import React from 'react';
import ContactsOverview from "./Pages/overview";
import ContactsDetail from "./Pages/detail";
import ContactsNew from "./Pages/new";
import ContactsDelete from "./Pages/delete";

export const ContactsPagesConfig = (value: any) => {

    return [
        {
            path: '/contacts/overview/:page',
            elements: <ContactsOverview value={value}/>,
        },
        {
            path: '/contacts/delete/:page/:deleteID',
            elements: <ContactsDelete value={value}/>,
        },
        {
            path: '/contacts/detail/:id',
            elements: <ContactsDetail value={value}/>,
        },
        {
            path: '/contacts/new/:page',
            elements: <ContactsNew value={value}/>,
        },
    ];
}

export const ContactsMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.subhead.contacts',
            route: '/contacts/overview/1',
            icon: 'contacts'
        },
    ]
}
