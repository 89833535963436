import IntlMessage from "../../component/const/IntlMessage";
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import TableCell from '@material-ui/core/TableCell';
function payment_state(text: string, id: any, index: number, VisitData: any, VisitDataDel: boolean,Store:any){

  let state: any = {
    'paid': {
      'label': <IntlMessage Store={Store} messageId="common.paid" />,
      'color': 'success',
      'text': 'light'
    },
    'ready': {
      'label': <IntlMessage Store={Store} messageId="common.pending" />,
      'color': 'warning',
      'text': 'light'
    },
    'awaiting_payment': {
      'label': <IntlMessage Store={Store} messageId="common.pending" />,
      'color': 'warning',
      'text': 'light'
    },
    'cancelled':{
      'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
      'color': 'danger',
      'text': 'dark'
    },
    'addressed':{
      'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
      'color': 'danger',
      'text': 'dark'
    }
  };

  let stateColor = 'primary';
  let stateText:any = [{'text': 'dark'}];
  let stateLabel = text;
  if(state[text] !== undefined) {
    stateColor = state[text].color
    stateText = [{'text': state[text].text}]
    stateLabel = state[text].label
  }

  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>
      <Badge data-id={id} bg={stateColor} text={stateText[0].text} id={"badge"+id+ "_" +index}>{stateLabel}</Badge></TableCell></>
  )

}

export default payment_state;
