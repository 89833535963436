import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate, useParams} from "react-router-dom";
import {handleSucces} from '../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseCustomerOverview} from "../../api/response/Customer";
import {useSelector} from "react-redux";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {useState} from "react";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import { Form } from 'react-bootstrap';

const CustomerOverview = (props: { value: any }) => {
    const {page} = useParams<{page: string;}>();
    const Store = useSelector((state: any) => state)
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const namespace_search = "Customer_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let response = ResponseCustomerOverview(limit, searchValue);
    const history = useNavigate();
    const destination = 'customer';

    const Configuration = {
        'SearchConfig': [{
            namespace: namespace_search,
            destination: destination,
            input: [
                {id: 'firstname', translation: 'common.firstname', param: 'firstname', method: 'like'},
                {id: 'lastname', translation: 'common.lastname', param: 'lastname', method: 'like'},
                {
                    id: 'status',
                    translation: 'common.status',
                    param: 'status',
                    method: '=',
                    individual: <Form.Group controlId={'status'} key={"select"} className="col" >
                        <Form.Label>Status</Form.Label>
                        <Form.Select  aria-label="Floating label select example" defaultValue={localStorage.getItem('Customer_searchValue_status') as string}>
                            <option value={''}>{IntlMessageValue('de','common.all.customer',Store)}</option>
                            <option value={'null'}>{IntlMessageValue('de','common.deactive.customer',Store)}</option>
                            <option value={'1'}>{IntlMessageValue('de','common.active.customer',Store)}</option>
                        </Form.Select>
                    </Form.Group>
                },

            ],
            button: ['common.search', 'common.del.search', 'common.button.new_costumer']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id', 'status', 'title', 'firstname', 'lastname'],
                'type': ['string', 'status', 'string', 'string', 'string'],
            },
            'tableHeader': [<IntlMessage Store={Store} messageId={'common.table.head.id'}/>,
                <IntlMessage Store={Store} messageId={'common.status'}/>,
                <IntlMessage Store={Store} messageId={'common.title'}/>,
                <IntlMessage Store={Store} messageId={'common.firstname'}/>,
                <IntlMessage Store={Store} messageId={'common.lastname'}/>],
            'tableHeaderWidth': ['20%', '20%', '20%', '20%', '20%'],
            'tablecolor': ['light'],
            'menu': {
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/customer/Detail', '/customer/delete/1'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            }
        }
    };
    if (response.length > 0) {
        return (
            <>
                <div className={'site'}>
                    <SearchJSX setSearchValue={setSearchValue} Store={Store}
                               SearchConfig={Configuration['SearchConfig']}/>
                    <br style={{clear: 'both'}}/>
                    <PielersTable Configuration={Configuration['table']}/>
                    <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces}
                                   destination={destination}/>
                </div>

            </>
        );
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default CustomerOverview;
