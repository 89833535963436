import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {budgetDetailApi, ContactsApiType} from './contactsApiType.interface';


export const Contacts = {
    getPosts: (limit:any,searchValue:any): Promise<ContactsApiType[]> => requests.get(`/v2/contacts/All/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<ContactsApiType[]> =>  requests.get(`/v2/contact/${id}/`),
    createPost: (post: ContactsApiType): Promise<ContactsApiType>  => requests.post('posts', post),
    updatePost: (post: any, id: any): Promise<ContactsApiType[]> =>
        requests.put(`/Content/Snippet/Single/${id}`, post),
    deletePost: (id: string): Promise<void> => requests.delete(`/v2/contacts/delete/${id}/`),
    getPostsAllContacts: (): Promise<ContactsApiType[]> => requests.get(`/v2/contact/Find/All/`),
    getAPostBudget: (id: string): Promise<budgetDetailApi[]> =>  requests.get(`/v2/budget/${id}/`),
};
