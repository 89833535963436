import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ReactDOM from "react-dom";
import {useRef} from "react";
import {useSelector} from "react-redux";
import CustomerSettlementDetail from "./detail";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const CustomerSettlementOverview = (value:any) => {

        const Store = useSelector((state: any) => state)
        const month  = ['',IntlMessageValue('de','january',Store),IntlMessageValue('de','february',Store),
        IntlMessageValue('de','march',Store),IntlMessageValue('de','april',Store),
        IntlMessageValue('de','may',Store),IntlMessageValue('de','june',Store),
        IntlMessageValue('de','july',Store),IntlMessageValue('de','august',Store),
        IntlMessageValue('de','september',Store),IntlMessageValue('de','october',Store),
        IntlMessageValue('de','november',Store),IntlMessageValue('de','december',Store)]

        const heute = new Date();
        let nowYear = heute.getFullYear();
        let backYear = 2;


        let months = [];
        const inputRef = useRef(null);
        for(let xs=1;xs<month.length;xs++){
            months.push(<>
                <option value={zeroPad(xs, '2')}>{month[xs]}</option>
            </>)
        }

        let years = [];
        for(let y=0;y<=backYear;y++){
            years.push(<>
                <option value={nowYear - y}>{nowYear - y}</option>
            </>)
        }



        const setHourly = () => {
            let monthSelect = (document.getElementById('month') as HTMLSelectElement).value;
            let yearSelect = (document.getElementById('year') as HTMLSelectElement).value;

            let end = parseInt(monthSelect)+1
            let selectYear = yearSelect
            let start = yearSelect+'-'+monthSelect+'-01';
            let ende = yearSelect+'-'+(zeroPad(end,2))+'-01'
            let el = document.getElementById('tempItem');
            if(el !== undefined && el !== null) {
                el.remove();
            }
            let newElement = (<CustomerSettlementDetail inputRef={inputRef} Store={Store} id={14} start={start} AllUser={null} backYear={backYear} CalculateTime={CalculateTime} nowYear={nowYear} year={selectYear} end={ende} month={month} zeroPad={zeroPad} />);
            let temp = document.createElement('div');
            temp.id = 'tempItem';
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById('content');
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

        return (
            <>
                <div id={'wrapperContent'}>
                    <Row className={'whitepad'}>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.year'}/></Form.Label>
                                <Form.Select id={'year'}>
                                    {years}
                                </Form.Select>
                            </Form.Group>
                        </Col>  <Col>
                        <Form.Group className="mb-3">
                            <Form.Label><IntlMessage Store={Store} messageId={'common.month'}/></Form.Label>
                            <Form.Select id={'month'}>
                                {months}
                            </Form.Select>
                        </Form.Group>
                    </Col>  <Col>
                        <br />
                        <Button variant="success" onClick={() => setHourly()}><IntlMessage Store={Store} messageId={'common.button.retrieve_customer_statement'}/></Button>
                    </Col>
                    </Row>
                    <br/>
                    <div id={'content'}/>
                </div>


            </>
        )
    }


    const CalculateTime = (start:any,end:any) => {

        let smin = parseInt(start.split(':')[1]);

        let sh = parseInt(start.split(':')[0]);

        let shmin = sh * 60;

        let emin = parseInt(end.split(':')[1]);

        let eh = parseInt(end.split(':')[0]);

        let ehmin = eh * 60;

        let ming =  (ehmin +emin) - (shmin + smin);

        return (ming / 60).toFixed(2);

    }

    const zeroPad = (num:any, places:any) => {
        let zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }



export default CustomerSettlementOverview;
