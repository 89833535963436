import {Row} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import IntlMessage, {IntlMessageValue} from '../../component/const/IntlMessage';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import React from 'react';
import Col from 'react-bootstrap/Col';

function url(data: any, i: number,optionalFuncFirst:any = false,Store:any) {


  let required = false;
  if(data.required !== undefined && data.required !== null && data.required !== false){
    required = true;
  }

  let Setautocomplete;
  if(data.type === 'password') {
    Setautocomplete = "new-password";
  } else {
    Setautocomplete = "on";
  }
  let index = data.id.split('url')[1]


  return (<>
      {switcher(data,index,Store)}
      {interneURL(data,index,Setautocomplete,required,Store)}
      {externeURL(data,index,Setautocomplete,required,Store)}
    </>
  )

}

export default url;

const _handleEnter = function(e: any,name:any,i:any) {

  let checked = e.target.checked;
  if(checked === true) {
    e.target.checked = true
    document.getElementById('flexSwitchCheckChecked'+ i)?.setAttribute('checked','checked')
    document.getElementById('extern'+ i)?.setAttribute('style','display:block')
    document.getElementById('extern'+i+'url')?.setAttribute('name',name)
    document.getElementById('intern'+ i)?.setAttribute('style','display:none')
    document.getElementById('intern'+i+'url')?.setAttribute('name','')
  } else {
    e.target.checked = false
    document.getElementById('flexSwitchCheckChecked'+ i)?.removeAttribute('checked')
    document.getElementById('intern'+ i)?.setAttribute('style','display:block')
    document.getElementById('intern'+i+'url')?.setAttribute('name',name)
    document.getElementById('extern'+ i)?.setAttribute('style','display:none')
    document.getElementById('extern'+i+'url')?.setAttribute('name','')
  }
}
const validate = 'einen gültigen Wert';

function switcher(data:any,i:any,Store:any){

  return (
    <>
    <Row>
      <Col>
        <div className="form-check form-switch "
             style={{marginTop: '10px', marginBottom: '10px', paddingLeft: '0px'}}>
          <div className={'float-start'}>
            <Row>
              <Col style={{maxWidth: '80px'}}><label className="form-check-label"><IntlMessage Store={Store} messageId="module.text_with_image.contentswitch.intern.url" /></label></Col>
              <Col style={{maxWidth: '80px', padding: '0px', }}>
                <input className="form-check-input ml-0" type="checkbox" id={"flexSwitchCheckChecked"+ i}
                       onClick={(e) => _handleEnter(e,data.name,i)}
                />
              </Col>
              <Col style={{maxWidth: '60px',marginLeft: '2em'}}>
                <label className="form-check-label"><IntlMessage Store={Store} messageId="module.text_with_image.contentswitch.extern.url" /></label>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
    </>
  )
}

function interneURL(data:any,i:number,Setautocomplete:any,required:any,Store:any) {
  data.legende = IntlMessageValue('de','module.text_with_image.legende.intern.url',Store);
  data.placeholder = '/magazin/testbeitrag2/';

  return (
    <>
      <Row id={'intern'+ i}>
        <Form.Group controlId={"intern" + i + 'url'} key={"intern" + i + 'url'}>
          <Form.Label key={"formFileLabel" + i}>{data.label}</Form.Label>
          <InputGroup className="mb-3" id={'intern' + i}>
            <InputGroup.Text>Interne URL</InputGroup.Text>
            <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern}
                          title={data.title} type={data.type} defaultValue={data.value} placeholder={data.placeholder}
                          key={"intern" + i + 'url'} required={required} />
            <Form.Control.Feedback type="invalid">
              <IntlMessage Store={Store} messageId="notification.invalid_value" preperator={'replace'} replace={validate} param={'[valid]'} />
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        {legende(data)}
      </Row>

      </>
  )
}

function externeURL(data:any,i:number,Setautocomplete:any,required:any,Store:any) {
  data.legende = IntlMessageValue('de','module.text_with_image.legende.extern.url',Store);
  data.placeholder = 'https://www.google.de/';
  return (
    <>
      <Row id={'extern'+ i}  style={{display:'none'}}>
        <Form.Group controlId={"extern" + i + 'url'} key={"extern" + i + 'url'}>
          <Form.Label key={"formFileLabel" + i}>{data.label}</Form.Label>
          <InputGroup className="mb-3" id={'intern' + i}>
            <InputGroup.Text>Externe URL</InputGroup.Text>
            <Form.Control autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern}
                          title={data.title} type={data.type} placeholder={data.placeholder}
                          key={"extern" + i + 'url'} defaultValue={data.value} required={required} />
            <Form.Control.Feedback type="invalid">
              <IntlMessage Store={Store} messageId="notification.invalid_value" preperator={'replace'} replace={validate} param={'[valid]'} />
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        {legende(data)}
      </Row>
    </>
  )
}
