
import {ContactsMenuConfig, ContactsPagesConfig} from "./contacts/modules";
import {CustomerMenuConfig, CustomerPagesConfig} from "./customer/modules";
import {BudgetMenuConfig, BudgetPagesConfig} from "./budget/modules";
import {MemberMenuConfig, MemberPagesConfig} from "./Member";

export const SettingPagesConfig = (value: any) => {

    return [
        ...ContactsPagesConfig(value),
        ...CustomerPagesConfig(value),
        ...BudgetPagesConfig(value),
        ...MemberPagesConfig(value)
    ];
}

export const SettingMenuConfig = (value: any) => {
    return [
        {
            header: 'navi.settings',
            icon: 'settings',
            menuitem: [
                ...ContactsMenuConfig(value),
                ...CustomerMenuConfig(value),
                ...BudgetMenuConfig(value),
                ...MemberMenuConfig(value)
            ]
        },
    ];
}
