import React from 'react';
import Image from 'react-bootstrap/Image'

function imageMerchant(data: any) {
  let link;

  if(data.altLink !== null && data.altLink !== undefined) {
    link = data.path;
  } else {
    link = 'https://d12pljs2iucqg.cloudfront.net/media/cache/marketplace_product_thumbnail/' + data.path;
  }

  let prelink;
  if(data.path.includes(process.env.REACT_APP_CDN_CLIENT) === true) {
    prelink = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL);
  } else {
    prelink = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+'/media/image/';
  }
  link = prelink + data.path;
  let width = '';
  if (data.width !== undefined) {
    width = data.width;
  } else {
    width = 'auto';
  }

  let height = '';
  if (data.height !== undefined) {
    height = data.height;
  } else {
    height = 'auto';
  }

  let att: string = '';
  if (data.class !== undefined) {

    att = data.class;
  }

  let id: string = '';
  if (data.id !== undefined) {

    id = data.id;
  }

  if(data.type !== undefined && data.type === 'video') {
    return (
      <>
        <br />
        <video id={id} width={width} height={height} controls>
          <source src={link} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  } else {
    return (
      <>
        <br /><Image src={link} id={id} className={att} style={{width: width, height: height}} />
      </>
    );
  }

}

export default imageMerchant;
