
import {TimeTrackingMenuConfig, TimeTrackingPagesConfig} from "./timetracking/modules";
import {HourlyBillingPagesConfig, HourlyBillingMenuConfig} from "./hourlyBilling/modules";

export const HourPagesConfig = (value: any) => {
    if(localStorage.getItem('userRole') as string === 'superadmin' || localStorage.getItem('userRole') as string === '1') {
        return [
            ...TimeTrackingPagesConfig(value),
            ...HourlyBillingPagesConfig(value)
        ];
    } else {
        return [
            ...TimeTrackingPagesConfig(value)
        ];
    }
}

export const HourMenuConfig = (value: any) => {
    if(localStorage.getItem('userRole') as string === 'superadmin' || localStorage.getItem('userRole') as string === '1') {
        return [
            {
                header: 'navi.time',
                icon: 'work_history',
                menuitem: [
                    ...HourlyBillingMenuConfig(value),
                    ...TimeTrackingMenuConfig(value)
                ]
            },
        ];
    } else {
        return [
            {
                header: 'navi.time',
                icon: 'work_history',
                menuitem: [
                    ...TimeTrackingMenuConfig(value)
                ]
            },
        ];
    }

}
