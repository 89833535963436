
const Rate = (form: any) => {

  let FormDatas = [];
  let inputs = form.elements;
  // Iterate over the form controlsc
  for (let i = 0; i < inputs.length; i++) {
    // Disable all form controls
    if (inputs[i].hasAttribute('name')) {
      if (!inputs[i].getAttribute('name').includes('elements') && inputs[i].getAttribute('name').includes('rate') && !inputs[i].getAttribute('name').includes('seo') && !inputs[i].getAttribute('name').includes('address')) {
        FormDatas.push(inputs[i].getAttribute('name'));
      }
    }
  }


  if(FormDatas.length > 0) {
    let elementsdataTranslation: string[] = [];
    let Translation = [];

      for (let ft = 0; ft < FormDatas.length; ft++) {

        if (!FormDatas[ft].includes('elements') && FormDatas[ft].includes('rate')  && !FormDatas[ft].includes('seo') && !FormDatas[ft].includes('address')) {
            elementsdataTranslation[ft] += FormDatas[ft] + ',';
        }
    }

    for (let t = 0; t < elementsdataTranslation.length; t++) {
      if (typeof (elementsdataTranslation[t]) !== "undefined") {
        let filtered = elementsdataTranslation[t].replace('undefined', '').split(',').filter(function(el) {
          return el !== "";
        });
        Translation.push(filtered);
      }
    }



    return Translation;
  } else {
    return [];
  }
};

export default Rate;