import React from 'react';
import BillOverview from "./Pages/overview";
import BillNew from "./Pages/new";

export const BillPagesConfig = (value: any) => {

    return [
        {
            path: '/bill/overview/:page',
            elements: <BillOverview value={value}/>,
        },
        {
            path: '/bill/new/:page',
            elements: <BillNew value={value}/>,
        },
    ];
}

export const BillMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.settlements.subhead.bill',
            route: '/bill/overview/1',
            icon: 'receipt'
        },]
}
