import Pagination from 'react-bootstrap/Pagination'
import React from 'react';


/**
 * @typedef PaginationJSXProps
 * @prop {string} response
 * @prop {string} handleSuccess
 * @prop {string} history
 * @prop {string} to
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface PaginationJSXProps {
  response: any,
  handleSuccess: (page: any, history: any, destination: any,to:any) => void,
  history: any,
  destination: any,
  to:any;
}

/**
 * Pagination
 * @component
 * @example
 * <PaginationJSX response={props.response} history={props.history} to={props.to} handleSuccess={handleSucces} destination={props.destination} />
 * @param {PaginationJSXProps} props
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const PaginationJSX: React.FC<PaginationJSXProps> = (props) => {
  let items = [];

  let leftPage = props.response[0].curPage -2;
  let PaginationStart = props.response[0].curPage;
  let rightPage = props.response[0].curPage + 2;

  if(PaginationStart === 1 ) {
    leftPage = props.response[0].curPage -0;
    rightPage = props.response[0].curPage + 4;
  } else if(PaginationStart === 2 ) {
    leftPage = props.response[0].curPage -1;
    rightPage = props.response[0].curPage + 3;
  }

  if(rightPage > props.response[0].MaxPage) {
    rightPage = props.response[0].MaxPage;
  }

  if((props.response[0].curPage -rightPage) === 0){
    leftPage = props.response[0].curPage -4;
  } else if((props.response[0].curPage -rightPage) === -1){
    leftPage = props.response[0].curPage -3;
  }

  if(leftPage < 1) {
    leftPage = 1;
  }

  for (let number = leftPage; number <= rightPage; number++) {
    let next = number;
    items.push(
      <Pagination.Item key={number} active={number === props.response[0].curPage} onClick={() => props.handleSuccess(next,props.history,props.destination,props.to)}>
        {next}
      </Pagination.Item>,
    );
  }


  return (
    <>
      <div>
        <Pagination><Pagination.First onClick={() => props.handleSuccess(1,props.history,props.destination,props.to)}/>{items}
          <Pagination.Last onClick={() => props.handleSuccess(props.response[0].MaxPage,props.history,props.destination,props.to)}/></Pagination>
      </div>
    </>
  )
}

export default PaginationJSX;
