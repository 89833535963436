import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import GetAvatarViewProps from '../../Interface/getAvatarView.interface';



const GetAvatarView = (props: GetAvatarViewProps) => {
  if(props.name !== null) {
    let addClass = (props.class !== undefined ? props.class : 'ticketAvatar')
    let color = (props.color !== undefined && props.color !== '' ? props.color : 'orange');
    return <Avatar className={addClass} style={{backgroundColor: color}}
                   title={props.name}>{props.name.charAt(0).toUpperCase()}</Avatar>;
  } else {
    return (<></>)
  }
};

export default GetAvatarView;