import React from 'react';
import MemberOverview from "./Pages/overview";
import MemberDetail from "./Pages/Detail";
import MemberNew from "./Pages/new";

export const MemberPagesConfig = (value:any) => {
  return [
      {
        path: '/Member/overview/:page',
        elements: <MemberOverview value={value} />,
      },
      {
        path: '/Member/Detail/:id',
        elements: <MemberDetail value={value} />,
      },
      {
        path: '/Member/Delete/:page/:deleteID',
        elements: <MemberOverview value={value} />,
      },
      {
        path: '/Member/new/new',
        elements: <MemberNew value={value} />,
      },
];
}

export const MemberMenuConfig = (value:any) => {
  return [
    {
      name: "settings.Member.header",
      route: '/Member/overview/1',
      icon: 'description'
    }
  ]
}
