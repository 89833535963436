import {ResponseBudgetbyCustomer, ResponseCustomerDetail} from "../../api/response/Customer";
import {useNavigate, useParams} from "react-router-dom";
import {Form, Tab, Tabs} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {ResponseAllContacts} from "../../../contacts/api/response/contacts";
import {
    clearLabels, fieldmaphealth_insurance,
    fieldmapSearch
} from "../../core/mapping";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {apiRoot} from "../../../../../@WUM/core/RestFullApi/ApiHook";
import {notify_save} from "../../../../../@WUM/core/component/const/notification.service";

const CustomerDetail = (props: { value: any }) => {

    let {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    const response = ResponseCustomerDetail(id);

    const AllContacts = ResponseAllContacts()

    const Budget = ResponseBudgetbyCustomer(id);
    if (response !== undefined && AllContacts !== undefined) {
        const save = () => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('token') as string) + "");

            var raw = JSON.stringify({
                "id": id,
                "title": ((document.getElementById('title') as HTMLInputElement).value !== '' ? (document.getElementById('title') as HTMLInputElement).value : ' '),
                "lastname": ((document.getElementById('lastname') as HTMLInputElement).value !== '' ? (document.getElementById('lastname') as HTMLInputElement).value : ' '),
                "firstname": ((document.getElementById('firstname') as HTMLInputElement).value !== '' ? (document.getElementById('firstname') as HTMLInputElement).value : ' '),
                "status": ((document.getElementById('status') as HTMLInputElement).value !== '' ? (document.getElementById('status') as HTMLInputElement).value : 0),
                "journey_fee": 0,
                "health_insurance": ((document.getElementById('health_insurance') as HTMLSelectElement).value !== '' ? (document.getElementById('health_insurance') as HTMLSelectElement).value : ' '),
                "supervisor": 0,
                "metas": [
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Wohnanschrift.Strasse",
                        "value": ((document.getElementById('Anschrift.Wohnanschrift.Strasse') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Wohnanschrift.Strasse') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Wohnanschrift.PLZ",
                        "value": ((document.getElementById('Anschrift.Wohnanschrift.PLZ') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Wohnanschrift.PLZ') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Wohnanschrift.Ort",
                        "value": ((document.getElementById('Anschrift.Wohnanschrift.Ort') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Wohnanschrift.Ort') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Rechnungsanschrift.Strasse",
                        "value": ((document.getElementById('Anschrift.Rechnungsanschrift.Strasse') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Rechnungsanschrift.Strasse') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Rechnungsanschrift.PLZ",
                        "value": ((document.getElementById('Anschrift.Rechnungsanschrift.PLZ') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Rechnungsanschrift.PLZ') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Anschrift.Rechnungsanschrift.Ort",
                        "value": ((document.getElementById('Anschrift.Rechnungsanschrift.Ort') as HTMLInputElement).value !== '' ? (document.getElementById('Anschrift.Rechnungsanschrift.Ort') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Datum.Geburtsdatum",
                        "value": ((document.getElementById('Datum.Geburtsdatum') as HTMLInputElement).value !== '' ? (document.getElementById('Datum.Geburtsdatum') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Telefonnummer.Telefon-Privat",
                        "value": ((document.getElementById('Telefonnummer.Telefon-Privat') as HTMLInputElement).value !== '' ? (document.getElementById('Telefonnummer.Telefon-Privat') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Textbereich.Anmerkungen",
                        "value": ((document.getElementById('Textbereich.Anmerkungen') as HTMLInputElement).value !== '' ? (document.getElementById('Textbereich.Anmerkungen') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Textfeld.Versichertennummer",
                        "value": ((document.getElementById('Textfeld.Versichertennummer') as HTMLInputElement).value !== '' ? (document.getElementById('Textfeld.Versichertennummer') as HTMLInputElement).value : ' '),
                    },
                    {
                        "customer_ID": id,
                        "key": "Textfeld.Pflegegrad",
                        "value": ((document.getElementById('Textfeld.Pflegegrad') as HTMLInputElement).value !== '' ? (document.getElementById('Textfeld.Pflegegrad') as HTMLInputElement).value : ' '),
                    }, {
                        "customer_ID": id,
                        "key": "Textfeld.Pflegegradseit",
                        "value": ((document.getElementById('Textfeld.Pflegegradseit') as HTMLInputElement).value !== '' ? (document.getElementById('Textfeld.Pflegegradseit') as HTMLInputElement).value : ' '),
                    }, {
                        "customer_ID": id,
                        "key": "Textfeld.Vormund",
                        "value": ((document.getElementById('Textfeld.Vormund') as HTMLInputElement).value !== '' ? (document.getElementById('Textfeld.Vormund') as HTMLInputElement).value : ' '),
                    }, {
                        "customer_ID": id,
                        "key": "Textfeld.Krankenkasse",
                        "value": ((document.getElementById('Textfeld.Krankenkasse') as HTMLInputElement).value !== '' ? (document.getElementById('Textfeld.Krankenkasse') as HTMLInputElement).value : ' '),
                    }

                ]
            })

            var requestOptions = {
                method: 'POST',
                body: raw,
                headers: myHeaders
            };
            let button = (document.getElementById("btn_save") as HTMLButtonElement);

            button.setAttribute("disabled", "disabled");
            fetch(apiRoot + "v2/customer/0/", requestOptions)
                .then(response => response.text())
                .then(result => rewrite())
                .catch(error => errorEvent(error));


        }

        const rewrite = () => {

            notify_save(Store);
            let button = (document.getElementById("btn_save") as HTMLButtonElement);

            button.removeAttribute("disabled");

        }

        const errorEvent = (error: any) => {
            console.log('error', error)
            let button = (document.getElementById("btn_save") as HTMLButtonElement);

            button.removeAttribute("disabled");
        }

        return (
            <>
                <div className={'whitepad'} id="divToPrint">
                    <Form>
                        <Tabs defaultActiveKey="allgemein" id="uncontrolled-tab-example" className="mb-3"
                              key={'tabindex'}>
                            <Tab eventKey="allgemein" title={IntlMessageValue('de', 'common.general', Store)}
                                 key={'allgemein'} id={'allgemein'}>
                                <h4><IntlMessage Store={Store} messageId={'common.general'}/></h4>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="title">
                                        <Form.Label><IntlMessage Store={Store} messageId={'common.title'}/></Form.Label>
                                        <Form.Select aria-label="Floating label select example"
                                                     defaultValue={response.title}>
                                            <option
                                                value={'Herr'}>{IntlMessageValue('de', 'common.mister', Store)}</option>
                                            <option
                                                value={'Frau'}>{IntlMessageValue('de', 'common.missis', Store)}</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="firstname">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'common.firstname'}/></Form.Label>
                                        <Form.Control type="text" defaultValue={response.firstname}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="lastname">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'common.lastname'}/></Form.Label>
                                        <Form.Control type="text" defaultValue={response.lastname}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="Datum.Geburtsdatum">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Datum.Geburtsdatum'}/></Form.Label>
                                        <Form.Control type="date"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Datum.Geburtsdatum').split('T')[0]}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Telefonnummer.Telefon-Privat">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Telefonnummer.Telefon-Privat'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Telefonnummer.Telefon-Privat')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Textbereich.Anmerkungen">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Textbereich.Anmerkungen'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textbereich.Anmerkungen')}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col} controlId="status">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.status'}/></Form.Label>
                                    <Form.Select aria-label="Default select example" defaultValue={response.status}>
                                        <option></option>
                                        <option value={1}>{IntlMessageValue('de','common.activated',Store)}</option>
                                        <option value={0}>{IntlMessageValue('de','common.deactivated',Store)}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            </Tab>
                            <Tab eventKey="address" title={IntlMessageValue('de', 'common.address', Store)}
                                 key={'address'} id={'address'}>
                                <Row className="mb-3">
                                    <h4><IntlMessage Store={Store} messageId={'Anschrift'}/></h4>
                                    <Form.Group as={Col} controlId="Anschrift.Wohnanschrift.Strasse">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Wohnanschrift.Strasse'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Wohnanschrift.Strasse')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Anschrift.Wohnanschrift.PLZ">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Wohnanschrift.PLZ'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Wohnanschrift.PLZ')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Anschrift.Wohnanschrift.Ort">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Wohnanschrift.Ort'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Wohnanschrift.Ort')}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <h4><IntlMessage Store={Store} messageId={'Anschrift.Rechnungsanschrift'}/></h4>
                                    <Form.Group as={Col} controlId="Anschrift.Rechnungsanschrift.Strasse">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Rechnungsanschrift.Strasse'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Rechnungsanschrift.Strasse')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Anschrift.Rechnungsanschrift.PLZ">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Rechnungsanschrift.PLZ'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Rechnungsanschrift.PLZ')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Anschrift.Rechnungsanschrift.Ort">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Anschrift.Rechnungsanschrift.Ort'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Anschrift.Rechnungsanschrift.Ort')}/>
                                    </Form.Group>
                                </Row>
                            </Tab>
                            <Tab eventKey="insurance"
                                 title={IntlMessageValue('de', 'common.health_insurance_budget', Store)}
                                 key={'insurance'} id={'insurance'}>
                                <Row className="mb-3">
                                    <h4><IntlMessage Store={Store} messageId={'common.health_insurance'}/></h4>
                                    <Form.Group as={Col} controlId="health_insurance">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Textfeld.Krankenkasse'}/></Form.Label>
                                        <Form.Select aria-label="Default select example"
                                                     defaultValue={fieldmaphealth_insurance(response)}
                                                     onClick={(e) => change_health_insurance(e, AllContacts)}>
                                            <option></option>
                                            {Buildhealth_insurance(AllContacts)}
                                        </Form.Select>
                                        <Col id={"health_insurance_informationen"} style={{background: 'lightgray'}}>{Showhealth_insurance(AllContacts, fieldmaphealth_insurance(response))}</Col>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="Textfeld.Krankenkasse">
                                        <Form.Label>Alter Wert <IntlMessage Store={Store}
                                                                            messageId={'Textfeld.Krankenkasse'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textfeld.Krankenkasse')}/>

                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Textfeld.Versichertennummer">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Textfeld.Versichertennummer'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textfeld.Versichertennummer')}/>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="Textfeld.Pflegegrad">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Textfeld.Pflegegrad'}/></Form.Label>
                                        <Form.Control type="text"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textfeld.Pflegegrad')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Textfeld.Pflegegradseit">
                                        <Form.Label><IntlMessage Store={Store} messageId={'Textfeld.Pflegegrad_seit'}/></Form.Label>
                                        <Form.Control type="date"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textfeld.Pflegegradseit')}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="Textfeld.Vormund">
                                        <Form.Label><IntlMessage Store={Store}
                                                                 messageId={'Textfeld.Vormund'}/></Form.Label>
                                        <Form.Control as="textarea"
                                                      defaultValue={fieldmapSearch(response, AllContacts, 'Textfeld.Vormund')}/>
                                    </Form.Group>
                                </Row>

                                {BuildBudget(Budget, Store)}
                            </Tab>

                        </Tabs>
                        <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}>
                            <IntlMessage Store={Store} messageId={'common.back'}/>
                        </Button>

                        <Button variant='success'
                                id={'btn_save'}
                                style={{float: 'right', marginRight: '15px'}}
                                type={'button'}
                                onClick={() => save()}
                        >
                            <IntlMessage Store={Store} messageId={'common.save'}/>
                        </Button>
                        <br style={{clear: 'both'}}/>

                    </Form>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default CustomerDetail;

const BuildBudget = (Budget: any, Store: any) => {


    if (Budget === undefined) {
        return;
    }

    let row = [];

    for (let x = 0; x < Budget.length; x++) {
        row.push(<><Row className="mb-3">
            <hr/>
            <h4>{Budget[x].budgetname} <Button variant={'info'} href={'/budget/Detail/'+Budget[x].id} className={'text-white m-0'}>Bearbeiten</Button></h4>
            <br/>
            <Form.Group as={Col} controlId={"formGrid" + clearLabels(Budget[x].budgetname).replaceAll(' ', '_')}>
                <Form.Label><IntlMessage Store={Store} messageId={'common.start_of_performance'}/></Form.Label>
                <Form.Control type="date" disabled={true}
                              placeholder={IntlMessageValue('de', 'common.start_of_performance.placeholder', Store)}
                              defaultValue={Budget[x].start_date}/>
            </Form.Group>
            <Form.Group as={Col} controlId={"formGrid" + clearLabels(Budget[x].budgetname).replaceAll(' ', '_')}>
                <Form.Label>{clearLabels(Budget[x].budgetname)}</Form.Label>
                <Form.Control type="text" disabled={true}
                              placeholder={clearLabels(Budget[x].budgetname) + IntlMessageValue('de', 'common.enter', Store)}
                              defaultValue={Budget[x].amount}/>
            </Form.Group>
            <Form.Group as={Col} controlId={"formGridturnus"}>
                <Form.Label><IntlMessage Store={Store} messageId={'common.turnus'}/></Form.Label>
                <Form.Select aria-label="Default select example" defaultValue={Budget[x].turnus} disabled={true}
                             placeholder={IntlMessageValue('de', 'common.turnus.placeholder', Store)}>
                    <option></option>
                    <option value="0">täglich</option>
                    <option value="1">monatlich</option>
                    <option value="2">jährlich</option>
                </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId={"formGridend_date"}>
                <Form.Label><IntlMessage Store={Store} messageId={'common.end_of_performance'}/></Form.Label>
                <Form.Control type="date" disabled={true}
                              placeholder={IntlMessageValue('de', 'common.end_of_performance.placeholder', Store)}
                              defaultValue={Budget[x].end_date}/>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formGridstatus">
                <Form.Label><IntlMessage Store={Store} messageId={'common.status'}/>:</Form.Label>
                <Form.Select aria-label="Default select example" disabled={true}
                             placeholder={IntlMessageValue('de', 'common.status.placeholder', Store)} defaultValue={Budget[x].status}>
                    <option value="1">Aktiviert</option>
                    <option value="0">Deaktiviert</option>
                </Form.Select>
            </Form.Group>

        </Row>
        </>)

    }

    row.unshift(<><h4>Budgets</h4></>)
    return row;

}

const Buildhealth_insurance = (health_insurance: any) => {

    let options = [];

    for (let x = 0; x < health_insurance.length; x++) {
        options.push(
            <>
                <option value={health_insurance[x].id}>{health_insurance[x].company}</option>
            </>
        )
    }

    return options;
}

const Showhealth_insurance = (health_insurance: any, searchid: any) => {

    let options = [];
    for (let x = 0; x < health_insurance.length; x++) {
        if (health_insurance[x].id === searchid) {
            options.push(
                <>
                    <div>
                        <p className={'m-0'}>{health_insurance[x].street}</p>
                        <p className={'m-0'}>{health_insurance[x].zip} {health_insurance[x].city}</p>
                        <p className={'m-0'}>{health_insurance[x].email}</p>
                        <p className={'m-0'}>{health_insurance[x].phone}</p>
                    </div>

                </>
            )
        }


    }

    return options;


}

const Showhealth_insurance_String = (health_insurance: any, searchid: any) => {

    let back: string = '';
    for (let x = 0; x < health_insurance.length; x++) {
        let street: string = (health_insurance[x].street !== null ? health_insurance[x].street : '');

        let zip: string = (health_insurance[x].zip !== null ? health_insurance[x].zip : '');

        let city: string = (health_insurance[x].city !== null ? health_insurance[x].city : '');

        let email: string = (health_insurance[x].email !== null ? health_insurance[x].email : '');

        let phone: string = (health_insurance[x].phone !== null ? health_insurance[x].phone : '');

        if (health_insurance[x].id === parseInt(searchid)) {
            back += '<div><p class="m-0">' + street + '</p> <p class="m-0">' + zip + ' ' + city + '</p><p class="m-0">' + email + '</p><p class="m-0">' + phone + '</p></div>'

        }

    }
    return back;

}

const change_health_insurance = (e: any, health_insurance: any) => {


    let cointaner = document.getElementById('health_insurance_informationen') as HTMLDivElement;

    cointaner.innerHTML = Showhealth_insurance_String(health_insurance, e.target.value)
}


/*class Budgets {

    static private function getTurnus(){

    return array(

        0 => 'once',

        1 => 'monthly',

        2 => 'yearly'

);

}*/
