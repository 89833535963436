import {logout} from "../service/AuthService";


const Logout = (value:any) => {
    logout();

    return(
        <></>
    )
}

export default Logout;
