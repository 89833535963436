
import './css/404.css'
import Button from "react-bootstrap/Button";

const Site404 = () => {

    return(
        <>
            <div className="message">
                <p className="rotate">ERROR</p>
                <h1 className="error">404</h1>

                <h2>Leider konnten wir die gesuchte Seite nicht finden.</h2>
                <br />
                <div style={{textAlign:'center'}}><Button type={'button'} href={(process.env.REACT_APP_LANDINGPAGES !== undefined ? process.env.REACT_APP_LANDINGPAGES : '/Blog/overview/1')} variant={'success'}>Zurück zur Startseite</Button></div>
            </div>
        </>
    )

}

export default Site404;
