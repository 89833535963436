import {MenuItem} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import React from "react";
import MenuIcon from "./MenuIcon";
import {IntlMessageValue} from "../component/const/IntlMessage";

const ItemMenu = (props:{items: any[],value:any,Store:any}) => {

    let Store = props.Store;
    let menuItem = [];
    if(props.items !== undefined){
    for(let i = 0;i<props.items.length;i++){
        if(props.items[i].icon !== undefined && props.items[i].icon !== '') {
            menuItem.push(<>
                <MenuItem key={'MenuItem'+i} icon={<MenuIcon icon={(props.items[i].icon !== undefined ? props.items[i].icon : '')} />}>
                    {IntlMessageValue(props.value,props.items[i].name,Store)}
                    <Link key={i} to={props.items[i].route}/>
                </MenuItem></>)
        } else {
            menuItem.push(<>
                <MenuItem key={'MenuItem'+i}>
                    {IntlMessageValue(props.value,props.items[i].name,Store)}
                    <Link key={i} to={props.items[i].route}/>
                </MenuItem></>)
        }

    }
    }
    return (<> {menuItem} </>);
}

export default ItemMenu;
