
import {CustomerSettlementMenuConfig, CustomerSettlementPagesConfig} from "./customerSettlement/modules";
import {CareServiceSettlementMenuConfig, CareServiceSettlementPagesConfig} from "./careSservicesSettlement/modules";
import {BillMenuConfig, BillPagesConfig} from "./bill/modules";

export const SettlementPagesConfig = (value: any) => {

    return [
        ...CustomerSettlementPagesConfig(value),
        ...CareServiceSettlementPagesConfig(value),
        ...BillPagesConfig(value)
    ];
}

export const SettlementMenuConfig = (value: any) => {
    return [
        {
            header: 'navi.settlements',
            icon: 'receipt_long',
            menuitem: [
                ...CustomerSettlementMenuConfig(value),
                ...CareServiceSettlementMenuConfig(value),
                ...BillMenuConfig(value)
            ]
        },
    ];
}
