import React from 'react';
import SunEditorConfig from './SuneditorConfig';
import BtnBack from './btn_Back';
import {useNavigate} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import LanguageSwitcher from './LanguageSwitcher';
import {isEmptyArray} from 'formik';
import saveComments from '../../API/saveComments';
import GetAvatarView from './getAvatarView';
import {dateFormat} from "../components/dateFormat";
import '../../css/SaveComments.css';
import IssueViewProps from '../../Interface/issue.interface';



const IssueView = (props:IssueViewProps) => {
  const user = {
    displayName: "Marcel Ugurcu",
    id:1,
    is_superuser:1
  };
  const history = useNavigate();
  let issue: any[] = [];
  let dummy = props.dummy;
  for (let x = 0; x < dummy.length; x++) {

    if (dummy[x].id === parseInt(props.id)) {
      issue.push(dummy[x]);
    }

  }

  let todo = [];

  if (issue !== undefined && !isEmptyArray(issue) && issue[0].todo !== undefined) {
    for (let x = 0; x < issue[0].todo.length; x++) {
      let checked = (issue[0].todo[x].checked === true ? true : false);
      todo.push(<>
        <li className='mb-20'>
          <input type='checkbox' className='task-list-item-checkbox' checked={checked}
                 readOnly={true} /> {issue[0].todo[x].value}
        </li>
      </>);
    }
  }
  let todolist = (
    <>
      <h3><LanguageSwitcher messageId={'common.todos'} /></h3>
      <ul style={{listStyle: 'none'}}>
        {todo}
      </ul>
    </>
  );

  let commentsWrapper = [];
  if (issue !== undefined && !isEmptyArray(issue) && issue[0].comments !== undefined) {

    let newComments = issue[0].comments

    for (let x = 0; x < newComments.length; x++) {
      if (newComments[x] !== null) {
        commentsWrapper.push(
            <>
              <div className="grayborder15">
                <h5><GetAvatarView name={newComments[x].User} color={'Orange'}
                                   class={'ticketAvatarFloatLeft mr-10'}/>{newComments[x].User} vom: {dateFormat(newComments[x].date,'de','us',true,true)}
                </h5>
                <p dangerouslySetInnerHTML={{__html: newComments[x].text}}/>
              </div>
            </>,
        );
      }
    }
  }

  return (
    <>
      <div>
        <h2>{issue[0].title}</h2>
        <p dangerouslySetInnerHTML={{__html: issue[0].IssueTranslation.description}} />
        <hr />
        {todolist}
        <hr />
        <h3><LanguageSwitcher messageId={'common.show.comment'} /></h3>
        <div>

          {commentsWrapper}
        </div>
        <div id={'commentHolder'}></div>
        <div className="grayborder15">
          <h4><LanguageSwitcher messageId={'new.comment'} /></h4>
          <form onSubmit={(e) => saveComments(e,issue[0].id)} >
            <input name={'User'} value={user.displayName} type={'hidden'}/>
            <input name={'translateable_id'} value={issue[0].id} type={'hidden'}/>
          <SunEditor
            key={'text'}
            name={'text'}
            setOptions={{
              buttonList: (SunEditorConfig()),
              attributesWhitelist:
                {
                  div: 'id',
                  p: 'id',
                  h: 'id'
                }
            }} />
          <button type="submit" className="btn btn-primary float-end mr-1 mt-2" ><LanguageSwitcher
            messageId={'new.comment.save'} /></button>
          </form>
          <div style={{clear: 'both'}} />

        </div>
      </div>
      <BtnBack history={history} addedClass={''} langKey={'common.back'} />
    </>
  );
};

export default IssueView;