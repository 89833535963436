import {ContactsApiType} from "../../../contacts/api/request/contactsApiType.interface";
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {CustomerApiType} from "./customerApiType.interface";

export const Customer = {
    getPosts: (limit:any,searchValue:any): Promise<CustomerApiType[]> => requests.get(`/v2/customers/All/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<CustomerApiType> =>  requests.get(`/v2/customer/${id}/`),
    createPost: (post: ContactsApiType): Promise<ContactsApiType>  => requests.post('posts', post),
    updatePost: (post: any, id: any): Promise<ContactsApiType[]> =>
        requests.put(`/Content/Snippet/Single/${id}`, post),
    deletePost: (id: string): Promise<void> => requests.delete(`/Content/Snippet/Single/${id}`),
    getAPostBudget: (id: string): Promise<CustomerApiType> =>  requests.get(`/v2/budget/byuser/${id}/`),
    deletePostCustomers: (id: string): Promise<void> => requests.delete(`/v2/customers/delete/${id}/`),
    getPostsInvoice: (limit:any,searchValue:any): Promise<CustomerApiType[]> => requests.get(`/v2/invoices/All/${limit}/${searchValue}`),
};
