import React from 'react';
import CareServiceSettlementOverview from "./Pages/overview";

export const CareServiceSettlementPagesConfig = (value: any) => {

    return [
        {
            path: '/CareServiceSettlement/overview/:page',
            elements: <CareServiceSettlementOverview value={value}/>,
        },
    ];
}

export const CareServiceSettlementMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.settlements.subhead.care_services_by_year',
            route: '/CareServiceSettlement/overview/1',
            icon: 'receipt'
        },]
}
