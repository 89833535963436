import deTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/de_DE.json";

import enTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/en_US.json";


export const RegisterLanguageDE = {
    ...deTicketsystemMessages
}

export const RegisterLanguageEN = {
    ...enTicketsystemMessages
}
