import {useState} from "react";
import {useSelector} from "react-redux";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {useNavigate, useParams} from "react-router-dom";
import PielersTable from "../../../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../../../@WUM/core/component/const/HandleSucces";
import {
    ResponseBudgetOverview
} from "../../../../hour/timetracking/api/response/timetracking.tsx";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {Form} from "react-bootstrap";

const BudgetOverview = (value:any) => {

    const {page} = useParams<{page: string}>();
    const Store = useSelector((state: any) => state)
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const namespace_search = "Budget_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const response = ResponseBudgetOverview(limit,searchValue);
    const history = useNavigate();
    const destination = 'budget';



    const Configuration = {
        'SearchConfig': [{
            namespace:namespace_search,
            destination: destination,
            input:[
                {id:'firstname',translation: 'common.firstname',param: 'customer.firstname',method:'like'},
                {id:'lastname',translation: 'common.lastname',param: 'customer.lastname',method: 'like'},
                {id:'budgetname',translation: 'common.table.head.budgetname',param: 'budgetname',method: 'end_like'},
                {
                    id: 'status',
                    translation: 'common.status',
                    param: 'status',
                    method: '=',
                    individual: <Form.Group controlId={'status'} key={"select"} className="col" >
                        <Form.Label>Status</Form.Label>
                        <Form.Select  aria-label="Floating label select example" defaultValue={localStorage.getItem('Budget_searchValue_status') as string}>
                            <option value={''}>{IntlMessageValue('de','budgets.dropwdown.status.alltimetracking',Store)}</option>
                            <option value={'null'}>{IntlMessageValue('de','budgets.dropwdown.status.deactivetimetracking',Store)}</option>
                            <option value={'1'}>{IntlMessageValue('de','budgets.dropwdown.status.activetimetracking',Store)}</option>
                        </Form.Select>
                    </Form.Group>
                },
            ],
            button: ['common.search','common.del.search','common.button.new_budget']
        }],
        'table':{
        'tableBody': {
            'data': response,
            'dataID': 'id',
            'underdata': true,
            'keys': ['id','status','budgetname','name', 'description', 'amount','customWageRate','start_date','end_date','creditFromThePreviousYear'],
            'type': ['string','status','string', 'string', 'string', 'string', 'amountToSum','amountToSum', 'string', 'string', 'amountToSum'],
        },
        'tableHeader': [<IntlMessage Store={Store} messageId={'common.table.head.id'}/>,<IntlMessage Store={Store} messageId={'common.status'}/>, <IntlMessage Store={Store} messageId={'common.table.head.budgetname'}/>,
            <IntlMessage Store={Store} messageId={'common.name'}/>,
            <IntlMessage Store={Store} messageId={'common.description'}/>,<IntlMessage Store={Store} messageId={'common.table.head.amount'}/>,
            <IntlMessage Store={Store} messageId={'common.table.head.customWageRate'}/>,<IntlMessage Store={Store} messageId={'common.table.head.start_Date'}/>,
            <IntlMessage Store={Store} messageId={'common.table.head.end_date'}/>,<IntlMessage Store={Store} messageId={'common.table.head.creditFromThePreviousYear'}/>],
        'tableHeaderWidth': ['5%','5%', '10%', '10%', '10%','10%', '10%','10%', '10%','10%','10%'],
        'tablecolor': ['light'],
        'menu': {
            'data': response,
            'menuDisable': false,
            'menutriggerDelete': true,
            'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
            'menuroute': ['/'+destination+'/Detail', '/'+destination+'/delete/1'],
            'menuParams': ['','id', 'id'],
            'menuIcons': ['pencil', 'Delete'],
        }
        }

    };
    if (response !== undefined && response.length > 0) {
        return (
            <>
                <div className={'site'}>
                    <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={Configuration['SearchConfig']} />
                    <br style={{clear:'both'}} />
                    <PielersTable Configuration={Configuration['table']} />
                    <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />
                </div>

            </>
        );
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}





export default BudgetOverview;
