import {
    ResponseinvoiceByUserYearPreview
} from "../../../../hour/timetracking/api/response/timetracking.tsx";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import Button from "react-bootstrap/Button";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import differenceTwoTime from "../../../../../@WUM/core/component/const/differenceTwoTime";
import {currencyFormat} from "../../../../../@WUM/core/component/const/currencyFormat";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
const CareServiceSettlementDetail = (props:{id:any,Store:any;customer:any,year:any,inputRef:any,start:any,end:any,AllUser:any,CalculateTime:any,zeroPad:any,month:any,backYear:any,nowYear:any}) => {
    let Store = props.Store;
    const response = ResponseinvoiceByUserYearPreview(props.customer,props.year)

    if(response !== undefined && props.AllUser !== undefined) {

        let row = [];
        let total = response[0][0].leistung[0].amount * 12;
        let monthName = ['',IntlMessageValue('de','january',Store),IntlMessageValue('de','february',Store),
            IntlMessageValue('de','march',Store),IntlMessageValue('de','april',Store),
            IntlMessageValue('de','may',Store),IntlMessageValue('de','june',Store),
            IntlMessageValue('de','july',Store),IntlMessageValue('de','august',Store),
            IntlMessageValue('de','september',Store),IntlMessageValue('de','october',Store),
            IntlMessageValue('de','november',Store),IntlMessageValue('de','december',Store)]
        for(let month =1;month<13;month++) {
            let sumh = 0;
            let previous_year :number|string = '';
        for(let x =0;x<response[0][0].activitiy.length;x++) {
            let activity = response[0][0].activitiy[x]
            if(month === 1) {
                previous_year = (response[0][0].leistung[0].credit_from_the_previous_year !== null && response[0][0].leistung[0].credit_from_the_previous_year  !== 0  ? currencyFormat((response[0][0].leistung[0].credit_from_the_previous_year !== null ? response[0][0].leistung[0].credit_from_the_previous_year : 0),'EUR') : '')
            } else {
                previous_year = '';
            }
            if (parseInt(activity.start_time.split('-')[1]) === month) {
                sumh = sumh + differenceTwoTime(activity.end_time, activity.start_time)

            }
        }
            total = total - (sumh * 22);
            row.push(<>
                <tr>
                    <td>{monthName[month]}</td>
                <td>{sumh.toFixed(2)} h = {currencyFormat((sumh * 22),'EUR')}</td>
                <td>{previous_year}</td>
                <td>{currencyFormat(response[0][0].leistung[0].amount,'EUR')}</td>
                <td>{currencyFormat(total,'EUR')}</td>
                <td></td>

                </tr>
            </>)
        }

        let months = [];

        for(let xs=1;xs<props.month.length;xs++){
            months.push(<>
                <option value={props.zeroPad(xs, '2')}>{props.month[xs]}</option>
            </>)
        }

        let years = [];
        for(let y=0;y<=props.backYear;y++){
            years.push(<>
                <option value={props.nowYear - y}>{props.nowYear - y}</option>
            </>)
        }



        return (
            <>

                    <div className={'whitepad'} id="divToPrint">
                        <h2><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.header'}/> {props.year}
                            <Button href={ApiRoot()+'v2/invoice/year/'+props.customer+'_'+props.year+'/'} variant="success" style={{float:'right'}} target={'new'}><IntlMessage Store={Store} messageId={'common.button.create_a_pdf'}/></Button>

                            </h2>
                        <Row>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.employe'}/></strong></Col>
                            <Col>{response[0][0].firstname} {response[0][0].lastname}</Col>
                            <Col></Col>
                            <Col> 22,- €</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.bl'}/> {props.year - 1}:</strong></Col>
                            <Col> {currencyFormat((response[0][0].leistung[0].credit_from_the_previous_year !== null ? response[0][0].leistung[0].credit_from_the_previous_year : 0),'EUR')}</Col>
                            <Col></Col>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.health_insurance_short'}/></strong></Col>
                            <Col>{response[0][0].Health_insurance[0].company}</Col>
                        </Row>
                        <Row>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.claim_new'}/> {props.year}:</strong></Col>
                            <Col>{currencyFormat(response[0][0].leistung[0].amount * 12,'EUR')}</Col>
                            <Col></Col>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.insurance_number_short'}/></strong></Col>
                            <Col>{response[0][0].Versichertennummer[0].value}</Col>
                        </Row>
                        <Row>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.total'}/></strong></Col>
                            <Col> {currencyFormat((response[0][0].leistung[0].credit_from_the_previous_year !== null ? response[0][0].leistung[0].credit_from_the_previous_year : 0) + response[0][0].leistung[0].amount * 12 ,'EUR')}</Col>
                            <Col></Col>
                            <Col><strong><IntlMessage Store={Store} messageId={'careServiceSettlement.detail.birthday'}/></strong></Col>
                            <Col>{response[0][0].Geburtsdatum[0].value}</Col>
                        </Row>
                        <br />
                        ({response[0][0].leistung[0].amount},-mtl. seit 2/2017)
                        <br />
                        <Table id={'tableContent'}>
                            <thead>
                            <tr>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.month'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.used'}/> <br /><IntlMessage Store={Store} messageId={'common.table.head.used_underline'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.vl_before'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.total.bl_new'}/><br />{currencyFormat(response[0][0].leistung[0].amount * 12,'EUR')}</th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.total'}/><br /><IntlMessage Store={Store} messageId={'common.table.head.car'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.remark'}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {row}
                            </tbody>
                        </Table>
                    </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

}

export default CareServiceSettlementDetail;
