import React from 'react';
import './@WUM/templates/default/assets/css/App.css';
import TemplateSwitcher from "./@WUM/templates";
import {useSelector} from "react-redux";
import './style.css';
function App() {
    const Store = useSelector((state: any) => state)
  return (
    <div className="App">
        <TemplateSwitcher layout={'default'} Store={Store}  />
    </div>
  );
}

export default App;
