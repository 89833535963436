import PielersserializeMerchantShippingType from '../helper/elementsshippingTypes';
import excape from '../helper/excape';

const serializItemElementType = (elementsdata: any, Data: any, form: any) => {

  let elementstmp: string = '';
  let zahl: number = 0;
  let Itemzahl: number = 0;
  let Iterator = elementsdata.length / 5;
  elementstmp += '[';
  if (elementsdata.length > 0) {
    for (let x = 0; x < Iterator; x++) {

      elementstmp += '{';

      let setter = x + 4 * (x+1);


      for (let i = 0; i < elementsdata.length; i++) {

        let type = elementsdata[i][0].split('[');
        let typeident = type[1].split(']');
        let head: number = parseFloat(typeident[0]);
        zahl = head;
        let Itemtype = elementsdata[i][0].split('[');
        let Itemtypeident = Itemtype[3].split(']');
        let Itemhead: number = parseFloat(Itemtypeident[0]);
        Itemzahl = Itemhead;
        if (Itemzahl === x) {
          let end = elementsdata.length - 1;
          if (i === end) {
            elementstmp += excape(PielersserializeMerchantShippingType(elementsdata[i], form)) + '}';
          } else if (i === setter) {
            elementstmp += excape(PielersserializeMerchantShippingType(elementsdata[i], form)) + '},';
          } else {
            elementstmp += excape(PielersserializeMerchantShippingType(elementsdata[i], form)) + ',';
          }
        }

      }
    }

    elementstmp += ']';
    //console.log(JSON.parse(elementstmp))
    Data['elements'][zahl].items = [];
    Data['elements'][zahl].items = JSON.parse(elementstmp);

  }
  return Data;

};

export default serializItemElementType;