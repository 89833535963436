import {notify_save} from "./notification.service";
import {apiRoot} from "../../RestFullApi/ApiHook";


const Create_save = (setup: { method: string, destination: string, btn_save: string, url: string, timeout: boolean, fields: ({ default: string | null; html: string; id: string })[] }) => {

    let jsonObject = {};
    let tmp = '';
    for (let x = 0; x < setup.fields.length; x++) {
        if (setup.fields[x].id === 'id' && setup.fields[x].html === 'none') {
            tmp = '{"' + setup.fields[x].id + '": ' + setup.fields[x].default + '}';
        } else {
            switch (setup.fields[x].html) {
                case 'input':
                    tmp = '{"' + setup.fields[x].id + '": "' + ((document.getElementById(setup.fields[x].id) as HTMLInputElement).value !== '' ? (document.getElementById(setup.fields[x].id) as HTMLInputElement).value : setup.fields[x].default) + '"}';
                    break;
                case 'select':
                    tmp = '{"' + setup.fields[x].id + '": "' + ((document.getElementById(setup.fields[x].id) as HTMLSelectElement).value !== '' ? (document.getElementById(setup.fields[x].id) as HTMLSelectElement).value : setup.fields[x].default) + '"}';
                    break;
            }
        }

        jsonObject = Object.assign({}, jsonObject, JSON.parse(tmp));
    }

    return jsonObject;

}


const rewrite = (Store:any,destination:any,timeout:any,btn_save:any) => {

    notify_save(Store);
    if(timeout === true) {
        setTimeout(() => window.location.href = '/' + destination + '/overview/1', 3000);
    } else {
        let button = (document.getElementById(btn_save) as HTMLButtonElement);

        button.removeAttribute("disabled");
    }
}

const errorEvent = (error: any,btn_save:any) => {
    console.log('error', error)
    let button = (document.getElementById(btn_save) as HTMLButtonElement);

    button.removeAttribute("disabled");
}

const newSave = (setup:any,Store:any) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('token') as string) + "");

    var raw = JSON.stringify(Create_save(setup))

    var requestOptions = {
        method: 'POST',
        body: raw,
        headers: myHeaders
    };
    let button = (document.getElementById(setup.btn_save) as HTMLButtonElement);

    button.setAttribute("disabled", "disabled");
    fetch(apiRoot + setup.url, requestOptions)
        .then(response => response.text())
        .then(result => rewrite(Store,setup.destination,setup.timeout,setup.btn_save))
        .catch(error => errorEvent(error,setup.btn_save));


}

export default newSave;
