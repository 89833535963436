import {useParams} from "react-router-dom";
import {ResponseDeleteTimetracking} from "../../api/response/timetracking.tsx";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";


const TimeTrackingDelete = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const {deleteID} = useParams<{deleteID: string }>();

    const destination = 'timetracking';


    if (deleteID) {
        ResponseDeleteTimetracking(deleteID);
        setTimeout(() => window.location.href = '/' + destination + '/overview/1', 3000);

    }

    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
    );
}

export default TimeTrackingDelete;
