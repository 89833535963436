import Badge from 'react-bootstrap/Badge';
import React from 'react';
import TopicProps from '../../Interface/topic.interface';




const Topic = (props:TopicProps) => {


  if (props.textColor === true) {
    return (
      <>
        <Badge pill={true} text={'dark'} bg={props.bg}>{props.text.name}</Badge>

      </>
    );
  } else {

    return (
      <>
        <Badge pill={true} text={'light'} bg={props.bg}>{props.text.name}</Badge>

      </>
    );
  }
};

export default Topic;