import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function invoicepdf(text: string, id: any, index: number, VisitData: any, VisitDataDel: any,Store:any){

  if(text !== '' && text !== undefined && text !== null) {

    return (
      <>
        <TableCell>
          <a href={text} download>
       <span className="material-icons" style={{verticalAlign: '-20%', color: 'red'}}>
picture_as_pdf
</span> {text}
          </a>

        </TableCell>
      </>
    );
  } else {
    return (
    <>
      <TableCell>
             </TableCell>
    </>
    );
  }
}

export default invoicepdf;
