/**
 * Refresh die Seite
 * @param page
 * @param history
 * @param destination
 * @param to
 * @constructor
 */


const Refresh = (page: any,history:any,destination:any,to:any) => useRefresh(history, '/'+destination+'/'+to+'/' + page, '/'+destination+'/'+to+'/' + page);


/**
 * Blendet Spinner nach Success ein oder aus
 * @param page
 * @param history
 * @param destination
 * @param to
 */
export const handleSucces = (page: any,history:any,destination:any,to:any) => {
    let spinner = document.getElementsByClassName('spinner');
    let site = document.getElementsByClassName('site');
    spinner[0]?.setAttribute('style', 'display:block');
    site[0]?.setAttribute('style', 'display:none');
    if (history.location === '/'+destination+'/'+to+'/' + page) {
        Refresh(history,page,destination,to);
        setTimeout (show, 2600);
    } else {
        history('/'+destination+'/'+to+'/' + page);
        setTimeout (show, 2600);
    }
};

/**
 *  Blendet den Spinner ein oder aus
 */
export const show = () => {
    const spinnerElement = document.getElementsByClassName('spinner');
    const siteElement = document.getElementsByClassName('site');
    spinnerElement[0]?.setAttribute('style','display:none');
    siteElement[0]?.setAttribute('style','display:block');
}

export default function useRefresh(history: any, path: string, resetRoute: string) {
    let handler: any;
    const refresh = () => {
        history(resetRoute);

        handler = setTimeout(() => history(path), 10);

        return handler;
    };
    //setTimeout(() => window.location.href = path, 1000);
   /* useEffect(() => {
        return () => handler && clearTimeout(handler);
    }, [handler]);*/


    return refresh;
}

