import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize';
import {config, output} from './configuration/mapping';
import PielersForm from '../../../../@WUM/core/form-serilizer';
import configuration from './configuration/Page/configuration';
import Spinner from 'react-bootstrap/Spinner';
import {ResponseDetail} from '../API/response/member/member';
import {memberApiType} from '../API/request/member/memberApi.interface';
import {member} from '../API/request/member/member';
import {notify_save} from '../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../@WUM/core/component/const/error.services';
import {handleSubmitForm} from '../../../../@WUM/core/component/const/handleSubmitForm';
import {Form} from 'react-bootstrap';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";



const MemberDetail = (value:any) => {

  const { id } = useParams<{ id: string }>();
  const response2 = ResponseDetail(id);
  const history = useNavigate();
  const Store = useSelector((state: any) => state)
  /**
   * Neuladen der Seite
   */
  const routeChange = (path:string) => {
    let paths = path;
    history(paths);
  };

  const [currentTutorial, setCurrentTutorial] = useState<memberApiType[]>((response2 !== true ? response2 : []));
  const [validated, setValidated] = useState(false);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init();



    let reauestData: memberApiType[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));


    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    member.updatePost(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        routeChange(`/Member/Detail/` + id);
      })
      .catch(e => {
        error(e);
      });

  };

  if (response2.hasOwnProperty('id') === true) {
  return ( 
    <div>
      <Form  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, id)}>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'Allgemein'} id={'Allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {PielersForm(configuration('dataColOne', response2, false,Store))}
          </Tab>
        </Tabs>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
          <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
          <Button variant='success'
            style={{float: 'right', marginRight: '15px'}}
            type={'submit'}
          >
            <IntlMessage Store={Store} messageId="common.save" />
          </Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
      </Spinner></>
    )
  }
}


export default MemberDetail;
