import React from 'react';
import DashbaordOverview from "./Pages/overview";

export const DashbaordPagesConfig = (value: any) => {

    return [
        {
            path: '/dashboard/overview/:page',
            elements: <DashbaordOverview value={value}/>,
        }
    ];
}

export const DashbaordMenuConfig = (value: any) => {
    return [{
        header: 'Dashboard',
        icon: 'support',
        menuitem: [
            {
                name: 'Dashboard',
                route: '/dashboard/overview/1',
                icon: 'dashboard'
            },
        ]
    }]
}
