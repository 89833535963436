import {useEffect, useState} from 'react';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {Contacts} from "../request/contacts";
import {budgetDetailApi, ContactsApiType} from "../request/contactsApiType.interface";

export const ResponseContactsOverview = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<ContactsApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Contacts.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseContactsDetail = (id:any) => {
    const [posts, setPosts] = useState<ContactsApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Contacts.getAPost(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseBudgetDetail = (id:any) => {
    const [posts, setPosts] = useState<budgetDetailApi[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Contacts.getAPostBudget(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseAllContacts = () => {
    const [posts, setPosts] = useState<ContactsApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Contacts.getPostsAllContacts()
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}
export const ResponseDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Contacts.deletePost(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}
