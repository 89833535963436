import {DashbaordMenuConfig} from "../modules/dashboard/modules";
import {HourMenuConfig} from "../modules/hour";
import {TicketMenuConfig} from "../modules/ticketsystem/modules";
import {SettingMenuConfig} from "../modules/settings";
import {SettlementMenuConfig} from "../modules/Settlement";


export const RegisterSitemap = (value: any) => {
    if(localStorage.getItem('userRole') as string === 'superadmin' || localStorage.getItem('userRole') as string === '1') {
        return [
            ...DashbaordMenuConfig(value),
            ...HourMenuConfig(value),
            ...SettlementMenuConfig(value),
            ...SettingMenuConfig(value),
            ...TicketMenuConfig(value),
        ]
    } else {
        return [
    ...DashbaordMenuConfig(value),
    ...HourMenuConfig(value)
        ]
    }
}
 
