import {UserActionType} from "../ActionType";
import {Dispatch} from "redux";


/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const UserRequestInit = () => {
    return {
        type: UserActionType.User_INIT,
    }
}

export const UserSuccess = (username: string,email:string) => {

    return {
        type: UserActionType.User_INIT,
        username: username,
        email:email,
    }
}

export const getUser = (username: string,email:any) => {

    return (dispatch: Dispatch) => {
        dispatch(UserSuccess(
            username,
            email
        ))
    }
}


