/**
 * Formatiert Datum
 * @param dateString
 */
export const formatDate = (dateString:any) =>
{
    if(dateString?.includes('T')) {
        dateString = dateString.split('T');
        return dateString[0];
    } else {
        return dateString;
    }
}