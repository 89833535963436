import {useParams} from "react-router-dom";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import {ResponseDelete} from "../../api/response/contacts";


const ContactsDelete = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const {deleteID} = useParams<{deleteID: string }>();

    const destination = 'contacts';


    if (deleteID) {
        ResponseDelete(deleteID);
        setTimeout(() => window.location.href = '/' + destination + '/overview/1', 3000);

    }

    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
    );
}

export default ContactsDelete;
