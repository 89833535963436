import React from 'react';
import CustomerSettlementOverview from "./Pages/overview";

export const CustomerSettlementPagesConfig = (value: any) => {

    return [
        {
            path: '/customerSettlement/overview/:page',
            elements: <CustomerSettlementOverview value={value}/>,
        },
    ];
}

export const CustomerSettlementMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.settlements.subhead.customer_bills_per_month',
            route: '/customerSettlement/overview/1',
            icon: 'receipt'
        },]
}
