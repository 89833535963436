import React, {useState} from 'react';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {useNavigate, useParams} from 'react-router-dom';
import ContentSwitch from '../../../../@WUM/core/component/const/ContentSwitch';
import {
  Response,
  ResponseAllSRoles,
  ResponseDelete,
} from '../API/response/member/member';
import {memberApiType} from '../API/request/member/memberApi.interface';
import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage';
import {ApiURL} from '../../../../@WUM/core/component/const/ApiURL';
import {getToken} from '../../../../@WUM/core/component/const/getToken';
import {useSelector} from "react-redux";
import './member.css'


const MemberOverview = (value:any) => {

  const {page,deleteID} = useParams<{page: string,deleteID: string}>();

  let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const Store = useSelector((state: any) => state)
  const MerchantSelect = () => {

    const [issue, setIssue] = useState([]); // Array instead of object

    React.useEffect(() => {
      let root = ApiURL();
      const fetchData = async () => {
        const result = await fetch(root+`User/byMerchant/`, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + getToken()
          }
        })
          .then(response => response.json())
          .then(response => {
            return response;
          })
          .catch(err => console.error(err));

        setIssue(result)
      }

      fetchData();
    }, [])

    return issue;
  }

  const history = useNavigate();
  /**
   * Reload Page
   */
 const routeChange = (paths:string) => {
    history(paths);
  };

  if(deleteID) {
    ResponseDelete(deleteID);
    routeChange('/Member/overview/1')
  }
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState<boolean>(false)
  const response = Response(limit,(searchValue !== '?search=1&' ? searchValue : ''));
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const Roles =  ResponseAllSRoles();
  const debug = (searchValue: string, page: string | undefined, Roles: memberApiType[] | boolean, modalShow: any, modalData: any) => {
    return
  }
  debug(searchValue,page,Roles,modalShow,modalData)

  const _handleEnter = function(e: any) {

    if (e.key === 'Enter') {
      let search = '';
      let title;
      let iterator = [];

      title = e.target.value;
      localStorage.setItem('searchInput',title);
      if (title !== undefined && title !== '') {
        iterator.push("username= like_with_start '"+title as string+"%'");
      }
      let usergroup;
        usergroup = (document.getElementById('searchSelect') as HTMLSelectElement).value;
      localStorage.setItem('searchSelect',usergroup);
      if (usergroup !== undefined && usergroup !== '') {
        iterator.push(usergroup)
      }


      for (let y = 0; y < iterator.length; y++) {
        if (y === (iterator.length - 1)) {
          search += iterator[y];
        } else {
          search += iterator[y] + '&';
        }
      }
      setSearchValue('?search=1&' + search);
    }
  }

  const _handleKeyFilters = function(e: any) {
    let usergroup ;
    let title ;
    if(e.target.value !== '0') {

      let search ='';

      let iterator=[];

      title = (document.getElementById('search') as HTMLInputElement).value;
      localStorage.setItem('searchInput',title);
      if(title !== undefined && title !== '') {
        iterator.push("username= like_with_start '"+title as string+"%'");
      }

        usergroup = (document.getElementById('searchSelect') as HTMLSelectElement).value;
      localStorage.setItem('searchSelect',usergroup);
      if(usergroup !== undefined && usergroup !== '') {
        iterator.push(usergroup)
      }



      for(let y = 0; y < iterator.length; y++){
        if(y === (iterator.length-1) ) {
          search += iterator[y];
        } else {
          search +=  iterator[y]+'&';
        }
      }
      setSearchValue('?search=1&' + search);

    }

  };

  const searchable = [
    {'param':
        {
         'placeholder': "user.search",
        }
    },
    {'option': {
        'value': 'usergroup=1',
        'label': "role.admin",
      }
    },
    {'option': {
        'value': 'usergroup= !=1',
        'label': "role.employee",
      }
    },

  ]

  let merchant = MerchantSelect();

  const [mode, setMode] = useState('cart');
  const destination = 'Member';

  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'img': '',
      'status': 'status',
      'statusActive': IntlMessageValue("de","common.activated",Store),
      'statusDeactive': IntlMessageValue("de","common.deactivated",Store),
      'link': 'slug',
      'prelink': '',
      'prelinksearch': 'translation.slug',

        'multiTitle': true,
        'Titles': ['first_name','last_name'],
        'title': 'username',
      'descriptionShow':true,
      'description': 'usergroup',
      'router': 'id',
        'descriptionMHP':true,
      'preroute': '/Member/Detail/',
      'edit': IntlMessageValue("de","user.edit",Store),
      'delete': true,
      'deleteLink': '/Member/Delete/'+page+'/[ID]',
      'Dontvisited': true,
    }
  }

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['status' ,'email', 'first_name', 'last_name','usergroup'],
      'type': ['status', 'string', 'string', 'string', 'usergroupMHP'],
    },
    'tableHeader': [<IntlMessage Store={Store} messageId="common.status" />, <IntlMessage Store={Store} messageId="common.email" />, <IntlMessage Store={Store} messageId="common.name.first" />,<IntlMessage Store={Store} messageId="common.name.last" />,<IntlMessage Store={Store} messageId="permissions" />],
    'tableHeaderWidth': ['20%', '20%', '20%', '20%', '20%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/Member/Detail', '/Member/Delete'],
      'menuParams': ['id', 'id'],
      'menuIcons': ['pencil','Delete'],
    },

  };

  if ( response !== true && response.length > 0) {

    return (
      <>
          <div id={'Member'}>
        <ContentSwitch pagenew={'new'} destinationnew={'Member'} tonew={'new'} text={<IntlMessage Store={Store} messageId="user.create" />}
                       response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                       Configuration={Configuration} setMode={setMode} destination={destination} page={page}
                       to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
                       modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData}
                       searchfield={searchable} allTaxon={''} _handleKeyFilters={_handleKeyFilters} _handleEnter={_handleEnter}
                       filter={false} merchantSelect={merchant} Store={Store} SearchParam={'?email'}/>
          </div>
  </>

    )
  } else {
    localStorage.setItem('searchInput','');
    localStorage.setItem('searchSelect','');
        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default MemberOverview;

