import {useEffect, useState} from "react";
import { checkLogin } from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {timetracking} from "../request/timetracking";
import {timetrackingApiDetailType} from "../request/timetrackingApiDetailType";
import {UserApiDetailType} from "../request/UserApiDetailType";
import {incoiceMonthlyApiDetail} from "../request/incoiceMonthlyApiDetail";
import {invoiceByUserYearPeeviewApiDetailType} from "../request/invoiceByUserYearPeeviewApiDetailType";
import {error} from '../../../../../@WUM/core/component/const/error.services'
/**
 * Sends response to Rest API for example
 * @memberOf example
 * @prop limit
 * @prop searchValue
 * @see {@link exampleApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseTimetrackingOverview = (post:any,searchValue:any) => {
    const [posts, setPosts] = useState<timetrackingApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPost(post,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [post,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseTimetrackingDetail= (id:any) => {
    const [posts, setPosts] = useState<timetrackingApiDetailType>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostDetail(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseHourlyBillingDetail= (id:any,start:any,end:any) => {
    const [posts, setPosts] = useState<timetrackingApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostHourlyBillingDetail(id,start,end)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                    // @ts-ignore
                    if(data.msg !== undefined && data.msg !== null && data.msg !== '') {
                        error(data);
                    }

                })
                .catch((err) => {

                    setIsError(true);
                });
        })()}, [id,start,end])


    return isError ? (posts ) : (posts);

}

export const ResponseHourlyBillingDetailFile= (id:any,start:any,end:any) => {
    const [posts, setPosts] = useState<incoiceMonthlyApiDetail[][]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostHourlyBillingDetailFile(id,start,end)
    .then((data) => {
        checkLogin(data);
        setPosts(data);
        // @ts-ignore
        if(data.msg !== undefined && data.msg !== null && data.msg !== '') {
            error(data);
        }
    })
    .catch((err) => {
        setIsError(true);
    });
})()}, [id,start,end])


return isError ? (posts ) : (posts);

}

export const InvoicePdf = (id:any,start:any,end:any,budget:any,invoice:any = 0)  => {
    const [posts, setPosts] = useState<incoiceMonthlyApiDetail[][]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostHourlyBillinginvoicePdf(id,start,end,budget,invoice)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id,start,end,budget,invoice])


    return isError ? (posts ) : (posts);

}

export const ResponseinvoiceByUserYearPreview= (id:any,start:any) => {
    const [posts, setPosts] = useState<invoiceByUserYearPeeviewApiDetailType[][]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostinvoiceByUserYearPreview(id,start)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id,start])


    return isError ? (posts ) : (posts);

}

export const ResponseUserById = (id:any) => {
    const [posts, setPosts] = useState<UserApiDetailType>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostUserById(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseAllUSer = () => {
    const [posts, setPosts] = useState<UserApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostAllUSer()
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponseAllCustomer = () => {
    const [posts, setPosts] = useState<UserApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostAllCustomer()
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponseBudgetOverview = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<UserApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.getAPostBudget(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}
export const ResponseDeleteBudget = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.deletePostBudget(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}

export const ResponseDeleteTimetracking = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await timetracking.deletePostTimetracking(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}
