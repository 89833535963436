import {LanguageSwitcherValue} from '../components/LanguageSwitcher';
import {isEmptyArray} from 'formik';
import HelperTopicOptionProps from '../../Interface/HelperTopicOption.interface';



  
  export const HelperTopicOption = (props:HelperTopicOptionProps) => {
    if (props.topic !== undefined && !isEmptyArray(props.topic)) {
      let OptionSwimlane = [];
  
  
  
      for (let x = 0; x < props.topic.length; x++) {
        OptionSwimlane.push(<>
          <option
            value={props.topic[x].id}
            selected={(props.selected !== undefined && props.selected === props.topic[x].name ? true : false)}
          >{LanguageSwitcherValue('section.' + props.topic[x].name.toLowerCase() + '')}</option>
        </>)
      }
  
      return (
        <>
          {OptionSwimlane}
        </>
      )
    } else {
      return (
          <></>
      )
    }
  }
  export default HelperTopicOption;