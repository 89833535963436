import { Form } from "react-bootstrap";
import IntlMessage from "./IntlMessage";
import React from "react";

/**
 * @typedef validatemessageProps
 * @prop {any} valid
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface ValidateMessageProps {
    valid: any,
    Store:any
}
/**
 * Validatemessage
 * @component
 * @example
 * <Validatemessage valid={data.valid} />
 * @param {ValidateMessageProps} props Übergabe werte zum Füllen des Spinners
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ValidateMessage: React.FC<ValidateMessageProps> = (props) => {
    const validate = (props.valid !== undefined ? props.valid : 'einen gültigen Wert');
    return (<>  <Form.Control.Feedback type="invalid">
        <IntlMessage Store={props.Store} messageId="notification.invalid_value" preperator={'replace'} replace={validate} param={'[valid]'} />
    </Form.Control.Feedback></>);
}
