import {LanguageSwitcherValue} from '../components/LanguageSwitcher';
import {isEmptyArray} from 'formik';
import HelperSwimelaneOptionProps from '../../Interface/HelperSwimelaneOption.interface';


  
  export const HelperSwimelaneOption = (props:HelperSwimelaneOptionProps) => {
    if (props.swimelane !== undefined && !isEmptyArray(props.swimelane)) {
      let OptionSwimlane = [];
  
      for (let x = 0; x < props.swimelane.length; x++) {
        OptionSwimlane.push(<>
          <option
            value={props.swimelane[x].id}
            selected={(props.selected !== undefined && props.selected === props.swimelane[x].name ? true : false)}
          >{LanguageSwitcherValue('swimelane.' + props.swimelane[x].name + '.label')}</option>
        </>)
      }
  
      return (
        <>
          {OptionSwimlane}
        </>
      )
    } else {
      return (
          <></>
      )
    }
  }

  export default HelperSwimelaneOption;