import React from 'react';
function rs() {

    return (
      <>
       <label>Row Start</label>
      </>
    );

}

export default rs;
