
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {handleSucces} from '../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {useSelector} from "react-redux";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {useState} from "react";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {ResponseInvoiceOverview} from "../../../../settings/customer/api/response/Customer";

const BillOverview = (props:{value:any}) => {
    const {page} = useParams<{page: string}>();
    const Store = useSelector((state: any) => state)
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const namespace_search = "bill_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let response = ResponseInvoiceOverview(limit,searchValue);
    const history = useNavigate();
    const destination = 'bill';

    const Configuration = {
        'SearchConfig': [{
            namespace:namespace_search,
            destination: destination,
            input:[
                {id:'name',translation: 'common.name',param: 'name',method: 'like'},
                {id:'number',translation: 'invoice.no',param: 'number',method: 'like'},
                {id:'date',type:"date",translation: 'common.date',param: 'date',method: 'end_like'}

            ],
            button: ['common.search','common.del.search','common.button.new.create_bill']
        }],
        'table': {
            'tableBody': {
                'data': response,
                'dataID': 'id',
                'underdata': true,
                'keys': ['id', 'number', 'date', 'name', 'street','city','zip','id'],
                'type': ['string', 'string', 'string', 'string', 'string', 'string','string', 'downloadPDF|cid|period|period|budget_ID|id'],
            },
            'tableHeader': [<IntlMessage Store={Store} messageId={'common.id'}/>,
                <IntlMessage Store={Store} messageId={'invoice.no'}/>,
                <IntlMessage Store={Store} messageId={'common.date'}/>,
                <IntlMessage Store={Store} messageId={'common.name'}/>,
                <IntlMessage Store={Store} messageId={'common.street'}/>,
                <IntlMessage Store={Store} messageId={'common.city'}/>,
                <IntlMessage Store={Store} messageId={'common.zip'}/>,
                <IntlMessage Store={Store} messageId={'common.bill'}/>],
            'tableHeaderWidth': ['20%', '20%', '20%', '20%', '20%'],
            'tablecolor': ['light'],
            'menu': {
                'deactive':true,
                'data': response,
                'menuDisable': false,
                'menutriggerDelete': true,
                'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                    <IntlMessage Store={Store} messageId="common.delete"/>],
                'menuroute': ['/customer/Detail', '/Invoice/Delete/timetracking/[ID]'],
                'menuParams': ['', 'id', 'id'],
                'menuIcons': ['pencil', 'Delete'],
            }
        }
    };
    if (response.length > 0) {
    return (
      <>
          <div className={'site'}>
              <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={Configuration['SearchConfig']} />
              <br style={{clear:'both'}} />
          <PielersTable Configuration={Configuration['table']} />
          <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />
          </div>

      </>
    );
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default BillOverview;
