import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import Cards from "../../../../@WUM/core/component/const/dashboardCards";

const DashbaordOverview = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    if(localStorage.getItem('userRole') as string === 'superadmin' || localStorage.getItem('userRole') as string === '1') {
    return (<>
    <Row >
        <Col className={'DashbaordRow'}>
            <h3 style={{marginBottom:'15px'}}><IntlMessage Store={Store} messageId={'common.header.customer'}/></h3>
            <Row >
                <Col><Cards title={'dashboard.care_services_by_year.header'} href={'/CareServiceSettlement/overview/1'} text={'dashboard.care_services_by_year.description'} Store={Store} /></Col>
                <Col><Cards title={'dashboard.customer_bills_per_month.header'} href={'/customerSettlement/overview/1'} text={'dashboard.customer_bills_per_month.description'} Store={Store} /> </Col>
            </Row>
        </Col>
        <Col className={'DashbaordRow'}>
            <h3 style={{marginBottom:'15px'}}><IntlMessage Store={Store} messageId={'common.header.employe'}/></h3>
            <Row >
                <Row>
                    <Col><Cards title={'dashboard.hourlybilling.header'} href={'/hourlybilling/overview/1'} text={'dashboard.hourlybilling.description'} Store={Store} /></Col>
                    <Col><Cards title={'dashboard.timetracking.header'}  href={'/timetracking/overview/1'} text={'dashboard.timetracking.description'} Store={Store} /></Col>
                </Row>
            </Row>
        </Col>

    </Row>
        <br />
        <Col className={'DashbaordRow'} style={{marginLeft:0}}>
            <h3 style={{marginBottom:'15px'}}><IntlMessage Store={Store} messageId={'navi.settings'}/></h3>
        <Row >
            <Col><Cards title={'navi.subhead.contacts'} href={'/contacts/overview/1'} text={'dashboard.contacts.description'} Store={Store} /></Col>
            <Col><Cards title={'navi.settings.subhead.customer'} href={'/customer/overview/1'} text={'dashboard.customer.description'} Store={Store} /></Col>
            <Col><Cards title={'navi.settlements.subhead.budget'} href={'/budget/overview/1'} text={'dashboard.budget.description'} Store={Store} /></Col>
            <Col><Cards title={'settings.Member.header'} href={'/Member/overview/1'} text={'dashboard.Member.description'} Store={Store} /></Col>
        </Row>
        </Col>
    </>)
}else {
        return (<>
            <Row >
                <Col className={'DashbaordRow'}>
                    <h3 style={{marginBottom:'15px'}}><IntlMessage Store={Store} messageId={'common.header.employe'}/></h3>
                    <Row >
                        <Row>
                            <Col><Cards title={'dashboard.timetracking.header'}  href={'/timetracking/overview/1'} text={'dashboard.timetracking.description'} Store={Store} /></Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </>)
    }
}

export default DashbaordOverview;


