import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function dateCut(text: string, id: any, index: number, VisitData: any, VisitDataDel: any,Store:any){

  let CompletDate = text.split('|');
  let date = CompletDate[0];
  let Time = CompletDate[1];

  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>{date}<br /><span style={{color: '#b0b0b0',fontSize: '14px', lineHeight: '1.3em'}}>{Time} Uhr</span></TableCell>
    </>
  );

}

export default dateCut;
