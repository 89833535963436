import Row from "react-bootstrap/Row";
import FetchSelect from "../../../../@WUM/core/component/const/Fetchselect";
import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";

export const clearLabels = (label:any) => {

    return  label.replaceAll('.',' ').replaceAll('Anschrift','').replaceAll('Textfeld','').replaceAll('Datum','').replaceAll('Textbereich','')
}

export const Metaaddress = (response:any,AllContacts:any) => {

    let size = 12;

    let doing = 0;

    let address = [];



    for(let x = 0;x<size;x++) {
        if(response.metas[x] !== undefined) {
            switch (response.metas[x].key) {
                case 'Anschrift.Wohnanschrift.Strasse':
                    doing = doing + 1;
                    address.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Anschrift.Wohnanschrift.PLZ':
                    doing = doing + 1;
                    address.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Anschrift.Wohnanschrift.Ort':
                    doing = doing + 1;
                    address.push(fieldmap(response,AllContacts,x))
                    break;
            }


        }
    }

    if(doing !== 0) {
        address.unshift(<><h4>Anschrift</h4></>)
        return (<> <Row className="mb-3">{address}</Row></>)
    } else {
        return (<></>)
    }

}

export const Metabilladress = (response:any,AllContacts:any) => {

    let size = 12;

    let doing = 0;

    let billadress = [];

    for(let x = 0;x<size;x++) {
        if(response.metas[x] !== undefined) {
            switch (response.metas[x].key) {
                case 'Anschrift.Rechnungsanschrift.Strasse':
                    doing = doing + 1;
                    billadress.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Anschrift.Rechnungsanschrift.PLZ':
                    doing = doing + 1;
                    billadress.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Anschrift.Rechnungsanschrift.Ort':
                    doing = doing + 1;
                    billadress.push(fieldmap(response,AllContacts,x))
                    break;
            }


        }
    }

    if(doing !== 0) {
        billadress.unshift(<><h4>Rechnungsanschrift</h4></>)
        return (<> <Row className="mb-3">{billadress}</Row></>)
    } else {
        return (<></>)
    }

}

export const Metainsurance = (response:any,AllContacts:any) => {

    let size = 12;

    let doing = 0;

    let insurance = [];



    for(let x = 0;x<size;x++) {
        if(response.metas[x] !== undefined) {
            switch (response.metas[x].key) {
                case 'Textfeld.Krankenkasse':
                    doing = doing + 1;
                    insurance.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Textfeld.Versichertennummer':
                    doing = doing + 1;
                    insurance.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Textfeld.Pflegegrad':
                    doing = doing + 1;
                    insurance.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Textfeld.Vormund':
                    doing = doing + 1;
                    insurance.push(fieldmap(response,AllContacts,x))
                    break;
            }


        }
    }

    if(doing !== 0) {
        insurance.unshift(<><h4>Krankenkasse</h4></>)
        return (<><Row className="mb-3">{insurance}</Row></>)
    } else {
        return (<></>)
    }

}

export const Metafield = (response:any,AllContacts:any) => {

    let size = 12;

    let doing = 0;

    let field = [];



    for(let x = 0;x<size;x++) {
        if(response.metas[x] !== undefined) {
            doing = doing + 1;
            switch (response.metas[x].key) {
                case 'Datum.Geburtsdatum':
                    field.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Telefonnummer.Telefon-Privat':
                    field.push(fieldmap(response,AllContacts,x))
                    break;
                case 'Textbereich.Anmerkungen':
                    field.push(fieldmap(response,AllContacts,x))
                    break;
            }


        }
    }

    if(doing !== 0) {
        return (<> <Row className="mb-3">{field}</Row></>)
    } else {
        return (<></>)
    }

}

export const fieldmap = (response:any,AllContacts:any,x:any) =>  {


    if ('Textfeld.Krankenkasse' === response.metas[x].key) {
        return (<><FetchSelect Data={AllContacts} id={'id'} value={'company'} Uid={response.health_insurance}
                               label={'Krankenversicherung'}/></>)
    } else if ('Textfeld.Pflegegrad' === response.metas[x].key) {
        return(<>
            <Form.Group as={Col} controlId={"formGrid"+clearLabels(response.metas[x].key).replaceAll(' ','_')}>
                <Form.Label>{clearLabels(response.metas[x].key)}</Form.Label>
                <Form.Control type="number" max='5' min="1"
                              placeholder={clearLabels(response.metas[x].key) + ' eingeben!'}
                              defaultValue={response.metas[x].value}/>
            </Form.Group></>)
    } else if ('Datum.Geburtsdatum' === response.metas[x].key) {
        return(<>
            <Form.Group as={Col} controlId={"formGrid"+clearLabels(response.metas[x].key).replaceAll(' ','_')}>
                <Form.Label>{clearLabels(response.metas[x].key)}</Form.Label>
                <Form.Control type="date" placeholder={clearLabels(response.metas[x].key) + ' eingeben!'}
                              defaultValue={response.metas[x].value.split('T')[0]}/>
            </Form.Group></>)
    } else {
        return(<>
            <Form.Group as={Col} controlId={"formGrid"+clearLabels(response.metas[x].key).replaceAll(' ','_')}>
                <Form.Label>{clearLabels(response.metas[x].key)}</Form.Label>
                <Form.Control type="text" placeholder={clearLabels(response.metas[x].key) + ' eingeben!'}
                              defaultValue={response.metas[x].value}/>
            </Form.Group></>)
    }

}

export const fieldmapSearch = (response:any,AllContacts:any,key:any) =>  {

    if(response.metas !== undefined) {
        for (let x = 0; x < response.metas.length; x++) {

            if (response.metas[x].key === key) {
                return response.metas[x].value
            }
        }
    }

    return '';

}

export const fieldmaphealth_insurance = (response:any) =>  {

    if(response.health_insurance !== undefined) {

        return response.health_insurance

    }

    return '';

}
