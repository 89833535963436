import React, { Component, ErrorInfo, ReactNode } from "react";
import Button from "react-bootstrap/Button";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error:any;
    errorInfo:any;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error:'',
        errorInfo:''
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.

        return {errorInfo: undefined, error: _, hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): State {
        console.error("Uncaught error:", error, errorInfo);

        return {error: this.state.error, hasError: this.state.hasError, errorInfo:errorInfo};
    }




    public render() {
       /* console.log(this.state.error)
        console.log(this.state.errorInfo)*/
        if (this.state.hasError) {
            return (<>
                <div style={{position:'absolute',top:'15%',left:'45%'}}>
                    <div style={{textAlign:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="220" height="220" preserveAspectRatio="xMidYMid meet" viewBox="0 0 64 64"><path fill="#ffce31" d="M5.9 62c-3.3 0-4.8-2.4-3.3-5.3L29.3 4.2c1.5-2.9 3.9-2.9 5.4 0l26.7 52.5c1.5 2.9 0 5.3-3.3 5.3H5.9z"/><g fill="#231f20"><path d="m27.8 23.6l2.8 18.5c.3 1.8 2.6 1.8 2.9 0l2.7-18.5c.5-7.2-8.9-7.2-8.4 0"/><circle cx="32" cy="49.6" r="4.2"/></g></svg><h1 style={{textAlign:'center'}}>Ups!</h1>
                    </div>
                <div>
                    <p><strong>Etwas funktioniert im Moment leider nicht.</strong> Bitte <br /> versuche es später noch einmal. Wenn es weiterhin Probleme <br />
                        gibt, wende dich bitte an das Unification Support Team.</p>
                </div>   <div>
                <Button variant={'secondary'} type={'button'} href={'/ticket/overview/1'} style={{float:'left'}}>Support Kontaktieren</Button>
                    {/* eslint-disable-next-line no-script-url */}
                <Button variant={'primary'} type={'button'} href={'javascript:location.reload()'}  style={{float:'right'}}>Erneut Versuchen</Button>
                </div>
                </div>
            </> );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
