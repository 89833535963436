import React from 'react';
import HourlyBillingOverview from "./Pages/overview";

export const HourlyBillingPagesConfig = (value: any) => {

    return [
        {
            path: '/hourlybilling/overview/:page',
            elements: <HourlyBillingOverview value={value}/>,
        },
    ];
}

export const HourlyBillingMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.time.subhead.hourlybilling',
            route: '/hourlybilling/overview/1',
            icon: 'receipt'
        },]
}
