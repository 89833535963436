import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import {Col} from 'react-bootstrap';
import React from 'react';
import Label from './label';
import GetAvatarView from './getAvatarView';
import Topic from './topic';
import LabelMaker from './labelMaker';
import NotificationMaker from './notificationMaker';
import IssueProps from '../../Interface/issueMaker.interface';



const Issue = (_props:IssueProps) => {

  let labelHolder = [];
  if (_props.label !== undefined && _props.label !== null) {
    for (let x = 0; x < _props.label.length; x++) {
      labelHolder.push(<Label text={_props.label[x].text} bg={_props.label[x].bg} />);
    }
  } else {
    labelHolder.push(<></>);
  }


  return (
    <>
      <Card style={{width: '100%', borderRadius: '15px', backgroundColor: _props.card.cardColor}}>
        <Card.Link as={'a'} href={'/ticket/issue/' + _props.card.id}>
          <Card.Body>
            <Row>
              <Col style={{maxWidth: '50px'}}>#{_props.card.id}</Col>
              <Col>{_props.card.user}</Col>
              <Col style={{maxWidth: '65px'}}>
                <GetAvatarView name={_props.card.user} color={_props.card.usercolor} /></Col>
            </Row>
            <Card.Title>
              {_props.card.title}
            </Card.Title>
            <Card.Subtitle className='mb-2 text-muted'>

            </Card.Subtitle>
            <Card.Text style={{margin: '0px'}}>
              <Topic text={_props.topic} bg={'light'} textColor={true} />
              <LabelMaker label={_props.label} />
            </Card.Text>
            <NotificationMaker comments={_props.card.comments} todo={_props.card.todo} />
          </Card.Body>
        </Card.Link>
      </Card>
    </>
  );

};

export default Issue;