import {useParams} from "react-router-dom";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import {ResponseDeleteBudget} from "../../../../hour/timetracking/api/response/timetracking.tsx";


const BudgetDelete = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    const {deleteID} = useParams<{deleteID: string }>();

    const destination = 'budget';


    if (deleteID) {
        ResponseDeleteBudget(deleteID);
        setTimeout(() => window.location.href = '/' + destination + '/overview/1', 3000);

    }

    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
    );
}

export default BudgetDelete;
