import React from 'react';
import TimeTrackingOverview from "./Pages/overview";
import TimeTrackingDetail from "./Pages/detail";
import TimeNew from "./Pages/new";
import TimeTrackingDelete from "./Pages/delete";

export const TimeTrackingPagesConfig = (value: any) => {

    return [
        {
            path: '/timetracking/overview/:page',
            elements: <TimeTrackingOverview value={value}/>,
        },
        {
            path: '/timetracking/delete/:page/:deleteID',
            elements: <TimeTrackingDelete value={value}/>,
        },
        {
            path: '/timetracking/detail/:id',
            elements: <TimeTrackingDetail value={value}/>,
        },
        {
            path: '/timetracking/new/:page',
            elements: <TimeNew value={value}/>,
        },
    ];
}

export const TimeTrackingMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.time.subhead.timetracking',
            route: '/timetracking/overview/1',
            icon: 'receipt_long'
        },
    ]
}
