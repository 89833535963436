import GetAvatarView from './getAvatarView';
import AllDeveloperProps from '../../Interface/AllDeveloper.interface';




const AllDeveloper = (props:AllDeveloperProps) => {

  let row = [];

  let developer = props.developer
  if (props.developer !== undefined && props.developer !== null) {
    for (let x = 0; x < developer.length; x++) {
      row.push(<>
            <div id={'Tech' + developer[x].developer.replaceAll(' ', '_')}>
              <GetAvatarView name={developer[x].developer} color={'blue'} class={'ticketAvatarFloatLeft'}/>
              <p className={'pAvatar'}>{developer[x].developer}</p>
            </div>
          </>
      )
    }
  }

    return (<>
        {row}

</>
)

}

export default AllDeveloper;