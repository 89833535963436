import TableCell from "@material-ui/core/TableCell";
import React from "react";


export const timeCut = (text: string, id: any, index: number, VisitData: any, VisitDataDel: any,Store:any) => {

    let newText;

    if(text === '' || text === null) {
        newText = '00:00:00'
    } else {
        newText = text.split(' ')[1]
    }
    return (
        <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>
            {newText}
        </TableCell>
        </>
    );
}
