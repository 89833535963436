import React from 'react';
import CustomerOverview from "./Pages/overview";
import CustomerDetail from "./Pages/detail";
import CustomerNew from "./Pages/new";
import CustomerDelete from "./Pages/delete";

export const CustomerPagesConfig = (value: any) => {

    return [
        {
            path: '/customer/overview/:page',
            elements: <CustomerOverview value={value}/>,
        },
        {
            path: '/customer/delete/:page/:deleteID',
            elements: <CustomerDelete value={value}/>,
        },
        {
            path: '/customer/detail/:id',
            elements: <CustomerDetail value={value} />
        },
        {
            path: '/customer/new/:page',
            elements: <CustomerNew value={value} />
        }
    ];
}

export const CustomerMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.settings.subhead.customer',
            route: '/customer/overview/1',
            icon: 'manage_accounts'
        },
    ]
}
