import {AuthPagesConfig} from "../@WUM/templates/default/Auth/modules";
import {TicketPagesConfig} from "../modules/ticketsystem/modules";

import {DashbaordPagesConfig} from "../modules/dashboard/modules";
import {HourPagesConfig} from "../modules/hour";
import {SettingPagesConfig} from "../modules/settings";
import {SettlementPagesConfig} from "../modules/Settlement";


export const RegisterRoute = (value: any) => {

    return [
        ...AuthPagesConfig(value),
        ...TicketPagesConfig(value),
        ...DashbaordPagesConfig(value),
        ...SettingPagesConfig(value),
        ...HourPagesConfig(value),
        ...SettlementPagesConfig(value)
    ]
}

