import LanguageSwitcher from "./LanguageSwitcher";
import React from "react";

/**
 * Formatiert Datum ins Deutsche oder US Format
 * @param dateString
 * @param format
 * @param nowFormat
 * @param withminutes
 */
export const dateFormat = (dateString:any, format:any, nowFormat:any, withminutes:any = false,convert:any =false) => {
    let newDate;
    if(format === 'de' && nowFormat !== 'de') {

        let newDateSting;

        if(convert !== false) {
            newDateSting = dateString.replace('CET',' ').replace('Z' ,'').split('.')[0]
        } else {
            newDateSting = dateString
        }
        let SplitDate = newDateSting?.split('-');
        if(typeof SplitDate !== 'undefined' && SplitDate.length > 0) {
            if(withminutes === true) {
                newDate = SplitDate[2].split(' ')[0] + '.' + SplitDate[1] + '.' + SplitDate[0] + ' ' + SplitDate[2].split(' ')[1]
            } else {
                newDate = SplitDate[2] + '.' + SplitDate[1] + '.' + SplitDate[0]
            }
        } else {
            newDate = dateString;
        }
    } else {
        newDate = dateString;
    }

    if(convert !== false) {
        return (<>{newDate} <LanguageSwitcher messageId={'common.clock'} /></>);
    } else {
        return newDate;
    }

}