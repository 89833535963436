import React from 'react';
import BudgetOverview from "./Pages/overview";
import ContactsNew from "./Pages/new";
import BudgetDetail from "./Pages/detail";
import BudgetDelete from "./Pages/delete";

export const BudgetPagesConfig = (value: any) => {

    return [
        {
            path: '/budget/overview/:page',
            elements: <BudgetOverview value={value}/>,
        },
        {
            path: '/budget/delete/:page/:deleteID',
            elements: <BudgetDelete value={value}/>,
        },
        {
            path: '/budget/detail/:id',
            elements: <BudgetDetail value={value}/>,
        },
        {
            path: '/budget/new/:page',
            elements: <ContactsNew value={value}/>,
        },
    ];
}

export const BudgetMenuConfig = (value: any) => {
    return [
        {
            name: 'navi.settlements.subhead.budget',
            route: '/budget/overview/1',
            icon: 'receipt'
        },]
}
