import {useEffect, useState} from "react";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {Customer} from "../request/customer";
import {CustomerApiType} from "../request/customerApiType.interface";

export const ResponseCustomerOverview = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<CustomerApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Customer.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    //error(err);
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseInvoiceOverview = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<CustomerApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Customer.getPostsInvoice(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    //error(err);
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseCustomerDetail = (id:any) => {
    const [posts, setPosts] = useState<CustomerApiType>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Customer.getAPost(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    //error(err);
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseBudgetbyCustomer = (id:any) => {
    const [posts, setPosts] = useState<CustomerApiType>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Customer.getAPostBudget(id)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    //error(err);
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}
export const ResponseDeleteCustomer = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await Customer.deletePostCustomers(id)
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}
