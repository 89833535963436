import Card from "react-bootstrap/Card";
import IntlMessage from "./IntlMessage";
import Button from "react-bootstrap/Button";
import '../css/dashboardCards.css'

/**
 * <Cards title={'dashboard.care_services_by_year.header'}
 * href={'/CareServiceSettlement/overview/1'}
 * text={'dashboard.care_services_by_year.description'}
 * Store={Store} />
 * @param props
 * @constructor
 */
const Cards = (props:{title:any,href:any,text:any,Store:any,withIMG?:any,img_path?:any,imgpositon?: 'top' | 'bottom',buttonText?:string}) => {

    let img = (<></>);
    if(props.withIMG !== undefined) {
        if(props.imgpositon === undefined) {
            props.imgpositon = 'top';
        }
        img = (<>
            <Card.Img variant={props.imgpositon} src={props.img_path} />
        </>)
    }

    let ButtonText = 'common.button.to.overview';

    if(props.buttonText !== undefined) {
        ButtonText = props.buttonText;
    }

    return (<>
        <Card className={'boxShadow DC_Card'}>
            {img}
            <Card.Body className={'DC_Body'}>
                <Card.Title className={'DC_Title'}><IntlMessage Store={props.Store} messageId={props.title}/></Card.Title>
                <Card.Text className={'DC_Text'}>
                    <IntlMessage Store={props.Store} messageId={props.text}/>
                </Card.Text>
                <Button variant="primary" className={'DC_Button'} href={props.href}><IntlMessage Store={props.Store} messageId={ButtonText}/></Button>
            </Card.Body>
        </Card>
        <br />
    </>)
}

export default Cards;
