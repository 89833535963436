import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function summe(text: string, id: any, index: number, data: any, VisitData: any, VisitDataDel: boolean,Store:any){

  //console.log(data);
  let currency = data.payment.currency_code
  let summe = parseInt(text) / 100;
  let ConvertSum = Intl.NumberFormat('de-DE', {style: 'currency',currency: currency, minimumFractionDigits: 2}).format(summe);
  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>{ConvertSum}</TableCell>
    </>
  );

}

export default summe;
